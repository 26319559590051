/**
 * Requesting, loading and getting information about iShare profiles (MapSource)
 * @module ol-ishare/profile
 */

import * as view from './view.js';
import { checkHttpStatusIsOk } from './http.js';

/**
 * @typedef {Object} Profile
 * @property {String} attribution Attribution for data in the profile
 * @property {Array<BaseMap>} baseMaps List of base map profiles
 * @property {Object} config Profile config
 * @property {String} config.owsUrl Base URL for WMS/ WFS
 * @property {String} defaultBaseMap Name of default base map
 * @property {String} defaultProfile Name of default profile
 * @property {Array<Number>} extent `[minx:Number, miny:Number, maxx:Number, maxy:Number]`
 * @property {Object} initialView `{easting: Number, northing: Number, zoom: Number}`
 * @property {Array<module:ol-ishare/profile~LayerGroup>} layerGroups List of layer group objects each of which has a `layers` property which is a list of layer objects
 * @property {String} mapName The name of this profile
 * @property {Array<module:ol-ishare/profile~Profile>} profiles List of all available profiles
 * @property {Object} projection Projection definition
 * @property {String} projection.SRID Spatial reference identifier
 * @property {String} projection.SRS Authority
 * @property {String} projection.definition Proj compatible definition
 * @property {String} projection.units Units of measure of the projection
 * @property {Array<Number>} resolutions List of resolutions (zoom levels) to be used with this profile
 */

/**
 * @typedef {Object} BaseMap
 * @property {String} attribution Copyright/ attribution for the base map
 * @property {String} displayName Name of the base map suitable for display to end-user
 * @property {String} format Image format (e.g. image/png)
 * @property {Array<String>} layers Array of layer names to request from the server
 * @property {String} mapName The name of the base map
 * @property {Array<Number>} resolutions List of resolutions (zoom levels) to be used with this profile
 * @property {String} url URL to request base map tiles
 */

/**
 * @typedef {Object} LayerGroup
 * @property {Array<module:ol-ishare/profile~Layer>} layers Array of Layer instances that belong to this group
 * @property {String} guid GUID that identifies this group
 * @property {String} displayName Name of the group suitable for display to end-user
 */

/**
 * @typedef {Object} Layer
 * @property {String} displayName Name of the layer suitable for display to end-user
 * @property {Array<module:ol-ishare/profile~Field>} fields Array of Field objects
 * @property {Boolean} hidden Whether the layer should be displayed a layer list etc.
 * @property {Boolean} infoClick Whether info is enabled for this layer
 * @property {Boolean} initiallyVisible Whether the layer should be visible when initially created
 * @property {String} layerName The layer ID
 * @property {Object} metadata Layer metadata
 * @property {Object} query Spatial query configuration
 * @property {String} searchField Field to use when performing an attribute search
 * @property {String} uniqueField Field containing unique values suitable for use as an ID (undefined in "mapserver" layer)
 * @property {ThematicClass} thematic Thematic class config including an SLD to apply to a WMS layer
 * @property {String} type Either "ows" or "mapserver"
 * @property {Object} geometry Geometry-related properties
 * @property {String} geometry.field Name of the field that contains geometries (undefined in "mapserver" layer)
 * @property {String} geometry.type 'POLYGON', 'LINESTRING', or 'POINT' (or undefined if "mapserver" layer)
 * @property {Boolean} geometry.multi Whether features can contain multiple geometries (or undefined if "mapserver" layer)
 * @property {Number} zIndex Order in which the layer will be rendered; layers with higher values will be shown above those with lower values
 * @property {Number} opacity Value between 0 (totally transparent) and 1 (completely opaque)
 */

/**
 * @typedef {Object} Field
 * @property {String} displayName Name of the field suitable for display to end-user
 * @property {Object|null} link Link information
 * @property {String} link.value Column from which to read link value
 * @property {String} link.baseUrl Optional URL with optional placeholder for link.value `{0}`
 * @property {String} name Column name
 */

var layerTypeOrder_ = ['POLYGON', 'LINESTRING', 'POINT'];

/**
 * Get Profile definition
 * @param {String} iShareUrl Base URL for iShare Web app
 * @param {String} profileName Name of profile to load
 * @returns {Promise} Resolves to {@link module:ol-ishare/profile~Profile} object, rejects with {Error}
 */
function getProfile(iShareUrl, profileName) {
  /**
   * Returns a MapSource URL
   * @private
   * @param {String} profileName Name of profile
   * @returns {String} URL to use to fetch profile config
   */
  iShareUrl = iShareUrl.replace(/\/$/, '') + '/';
  function getProfileUrl(profileName) {
    return (
      iShareUrl +
      'getdata.aspx?&type=MapSource&RequestType=JSON&ms=' +
      profileName
    );
  }

  /**
   * Returns a function that will request profile config from the server
   * @param {String} type Type of profile being requested ('root', 'profile' or 'basemap')
   * @param {String} profileName Name of profile
   * @returns {Promise} Resolves to object containing profile if found, or error if not
   */
  function profileRequest(type, profileName) {
    return fetch(getProfileUrl(profileName), { cache: 'no-cache' })
      .then(checkHttpStatusIsOk)
      .then(function (response) {
        return response.json();
      })
      .then(function (profile) {
        return {
          error: null,
          type: type,
          mapName: profileName,
          profile: profile
        };
      })
      .catch(function (err) {
        return { error: err, type: type, mapName: profileName, profile: null };
      });
  }

  /**
   * Returns a ThemeClass URL to get theme info for a thematic layer
   * @param {String} profileName Name of profile the thematic layer belongs to
   * @param {module:ol-ishare/profile~Layer} layerConfig Config for the layer
   * @returns {String} URL to fetch thematic layer theme info
   */
  function getThemeClassUrl(profileName, layerConfig) {
    return (
      iShareUrl +
      'getdata.aspx?Type=themeclass&RequestType=json&MapSource=' +
      profileName +
      '&layer=' +
      layerConfig.layerName
    );
  }

  /**
   * Returns a function that will request theme info for a thematic layer
   * @param {String} profileName Name of profile the thematic layer belongs to
   * @param {module:ol-ishare/profile~Layer} layerConfig Config for the layer
   * @returns {Promise} Promise that returns object with thematic classification if found, error if not
   */
  function themeClassRequest(profileName, layerConfig) {
    return fetch(getThemeClassUrl(profileName, layerConfig))
      .then(checkHttpStatusIsOk)
      .then(function (response) {
        return response.json();
      })
      .then(function (themeClass) {
        return {
          error: null,
          type: 'themeclass',
          layerName: layerConfig.layerName,
          themeClass: themeClass
        };
      })
      .catch(function (err) {
        return {
          error: err,
          type: 'themeclass',
          layerName: layerConfig.layerName,
          themeClass: null
        };
      });
  }

  var requests = [
    profileRequest('root', 'root'),
    profileRequest('profile', profileName)
  ];

  return Promise.all(requests).then(function (results) {
    // TODO Currently ignoring errors fetching root and main profile, we should
    // probably do something about errors...

    var rootProfile = results[0].profile;
    var profileDef = results[1].profile;
    profileDef.mapName = results[1].mapName;

    var requests = [];

    // Create a request for each basemap associated with the requested profile
    requests = requests.concat(
      rootProfile.baseMapSources
        .filter(function (baseMapDef) {
          return profileDef.baseMaps.indexOf(baseMapDef.mapName) > -1;
        })
        .map(function (profile) {
          return profileRequest('basemap', profile.mapName);
        })
    );

    // Create a request for each thematic layer
    var thematicLayerConfigs = getLayerConfigs(profileDef.layerGroups).filter(
      function (layerConfig) {
        return Boolean(layerConfig.thematic);
      }
    );
    requests = requests.concat(
      thematicLayerConfigs.map(function (layerConfig) {
        return themeClassRequest(profileDef.mapName, layerConfig);
      })
    );

    return Promise.all(requests).then(function (results) {
      var baseMapSources = [];
      var themeClasses = {};
      results.forEach(function (result) {
        if (result.type === 'basemap') {
          baseMapSources.push(result);
        } else {
          themeClasses[result.layerName] = result.themeClass;
        }
      });

      baseMapSources = baseMapSources.map(function (result) {
        var mapSource = result.profile;
        mapSource.mapName = result.mapName;
        return mapSource;
      });

      var profile = transformLegacyMapSource(
        rootProfile,
        profileDef,
        themeClasses,
        baseMapSources,
        iShareUrl
      );
      return profile;
    });
  });
}

/**
 * Transform an iShare v5.6 MapSource into an ol-ishare Profile
 * @param {Object} rootMapSource Root iShare MapSource
 * @param {Object} mainMapSource iShare MapSource
 * @param {Object} themeClasses Thematic classes for thematic layers
 * @param {Array<Object>} baseMapSources List of base map MapSource objects
 * @param {String} iShareUrl Base URL for iShare Web app
 * @returns {Profile} ol-ishare Profile object
 * @private
 */
function transformLegacyMapSource(
  rootMapSource,
  mainMapSource,
  themeClasses,
  baseMapSources,
  iShareUrl
) {
  rootMapSource = JSON.parse(JSON.stringify(rootMapSource));
  mainMapSource = JSON.parse(JSON.stringify(mainMapSource));
  themeClasses = JSON.parse(JSON.stringify(themeClasses));
  baseMapSources = JSON.parse(JSON.stringify(baseMapSources));

  var baseMapDefs = baseMapSources.map(function (baseMapSource) {
    return transformLegacyBaseMapSource(rootMapSource, baseMapSource);
  });

  var layerGroups = mainMapSource.layerGroups.map(function (group) {
    group.layers = group.layers.map(function (layer) {
      var themeClass = themeClasses[layer.layerName];
      return transformLegacyLayer(layer, themeClass);
    });
    return group;
  });

  var baseMapDef = baseMapDefs.find(function (profile) {
    return profile.mapName === mainMapSource.defaultBaseMap;
  });

  var projection = mainMapSource.projection;
  // Define a default minimal British National Grid projection if
  // the MapSource doesn't have a projection property; we're probably
  // dealing with an iShare v5.4 server
  if (!projection) {
    projection = {
      SRS: 'EPSG',
      SRID: 27700,
      definition: ''
    };
  }
  projection.units = mainMapSource.units;

  var profile = {
    mapName: mainMapSource.mapName,
    defaultProfile: rootMapSource.defaultMapSource,
    defaultBaseMap: mainMapSource.defaultBaseMap,
    profiles: rootMapSource.mapSources,
    baseMaps: baseMapDefs,
    extent: mainMapSource.bounds,
    projection: projection,
    initialView: mainMapSource.initialView,
    resolutions: baseMapDef.resolutions,
    attribution: baseMapDef.attribution,
    layerGroups: layerGroups,
    config: {
      owsUrl: getOwsUrl(iShareUrl, mainMapSource.mapName)
    }
  };

  return profile;
}

/**
 * Transform a base map MapSource into an ol-ishare base map definition
 * @param {Object} rootMapSource Root iShare MapSource
 * @param {Object} baseMapSource iShare base map MapSource
 * @returns {Object} baseMapDefinition ol-ishare base map definition
 * @private
 */
function transformLegacyBaseMapSource(rootMapSource, baseMapSource) {
  return {
    mapName: baseMapSource.mapName,
    displayName: rootMapSource.baseMapSources.find(function (ms) {
      return ms.mapName === baseMapSource.mapName;
    }).displayName,
    url: Array.isArray(baseMapSource.baseMapDefinition.uri)
      ? baseMapSource.baseMapDefinition.uri[0]
      : baseMapSource.baseMapDefinition.uri,
    layers: [baseMapSource.baseMapDefinition.name],
    format: baseMapSource.baseMapDefinition.options.format,
    resolutions: baseMapSource.baseMapDefinition.scales.map(
      view.scaleToResolution
    ),
    attribution: baseMapSource.baseMapDefinition.copyright
  };
}

/**
 * Transform a legacy MapSource field object into a shiny new Profile field object
 * @private
 * @param {Object} field Legacy MapSource field object
 * @param {Object} linkField Legacy MapSource link field object
 * @returns {module:ol-ishare/profile~Field} Profile field object
 */
function transformLegacyField(field, linkField) {
  var displayName = field.displayName;
  if (displayName === '') {
    displayName = field.name;
  } else if (displayName.replace(/_/g, '') === '') {
    displayName = null;
  } else {
    // Honour the leading underscore hack need to capitalise a
    // field displayName that is otherwise identical to the field.name
    displayName = displayName.replace(/^_+/, '');
  }
  var linkInfo = null;
  if (linkField) {
    linkInfo = {};
    linkInfo.value = linkField.uniqueId;
    linkInfo.baseUrl = linkField.baseUrl === '' ? null : linkField.baseUrl;
  }
  return {
    displayName: displayName,
    name: field.name,
    link: linkInfo
  };
}

/**
 * Transform a legacy MapSource layer object into a shiny new Profile layer object
 * @private
 * @param {Object} layer Legacy MapSource layer object
 * @param {Object} themeClass Optional thematic settings
 * @returns {module:ol-ishare/profile~Layer} Profile layer object
 */
function transformLegacyLayer(layer, themeClass) {
  var fields = layer.fields || [];
  var linkFields = layer.linkFields || [];
  linkFields = linkFields.reduce(function (linkFields, linkField) {
    linkFields[linkField.name] = linkField;
    return linkFields;
  }, {});
  fields = fields.map(function (field) {
    return transformLegacyField(field, linkFields[field.name]);
  });

  // Remove properties we don't want to keep on the
  // theme class (duplicate or redundant)
  if (themeClass) {
    delete themeClass.displayName;
    delete themeClass.layerName;
  }

  var layerType = layer.type;
  if (layerType === 'base') {
    layerType = 'mapserver';
  } else if (layerType === 'ows') {
    // Nothing to do leave as ows
  } else {
    console.info(
      'Unknown layer type:',
      layerType,
      'layerName: ',
      layer.layerName
    );
  }
  var uniqueField;
  var geometryType = 'UNKNOWN';
  var geometryField;
  var multiGeometry;

  if (layer.ows) {
    uniqueField = layer.ows.uniqueField;
    if (layer.ows.geometry) {
      geometryField = layer.ows.geometry.field;
      geometryType = layer.ows.geometry.type.toUpperCase();
      multiGeometry = layer.ows.geometry.multi;
    }
  }
  return {
    layerName: layer.layerName,
    displayName: layer.displayName,
    initiallyVisible: layer.initiallyVisible,
    hidden: !layer.active,
    infoClick: Boolean(layer.infoClick),
    query: layer.query,
    searchField: layer.searchField,
    uniqueField: uniqueField,
    type: layerType,
    fields: fields,
    thematic: themeClass || null,
    metadata: layer.metadata,
    geometry: {
      field: geometryField,
      type: geometryType,
      multi: multiGeometry
    },
    zIndex: layerTypeOrder_.indexOf(geometryType) + 1,
    opacity: 1
  };
}

/**
 * Return the base OWS (WMS/ WFS) URL given an iShareUrl and a profileName
 * @param {String} iShareUrl Base URL for iShare Web app
 * @param {String} profileName Name of profile
 * @returns {String} Base OWS URL
 */
function getOwsUrl(iShareUrl, profileName) {
  return iShareUrl + 'getows.ashx?mapsource=' + profileName;
}
/**
 * Returns a flattened list of layer configurations found in layerGroups for which the filter returns true
 * @param {Array<module:ol-ishare/profile~LayerGroup>} layerGroups An Array of {@link module:ol-ishare/profile~LayerGroup} instances
 * @param {Function} filterFunc Array#filter function, argument is a layer config, returns true if the layer is wanted
 * @returns {Array<module:ol-ishare/profile~Layer>} Array of layerConfig objects
 */
function filterLayerConfigs(layerGroups, filterFunc) {
  var layerConfigs = [];
  for (var m = 0, g; m < layerGroups.length; m++) {
    g = layerGroups[m];
    for (var n = 0, l; n < g.layers.length; n++) {
      l = g.layers[n];
      layerConfigs.push(l);
    }
  }
  return layerConfigs.filter(filterFunc);
}
/**
 * Returns a flattened list of all layer configurations found in layerGroups
 * @param {Array<module:ol-ishare/profile~LayerGroup>} layerGroups An Array of {@link module:ol-ishare/profile~LayerGroup} instances
 * @param {Array<String>} [layerNames] Optional list of names of required layers
 * @returns {Array<module:ol-ishare/profile~Layer>} Array of layerConfig objects
 */
function getLayerConfigs(layerGroups, layerNames) {
  var filterFunc;
  if (layerNames) {
    filterFunc = function (layerConfig) {
      return layerNames.indexOf(layerConfig.layerName) > -1;
    };
  } else {
    filterFunc = function () {
      return true;
    };
  }
  return filterLayerConfigs(layerGroups, filterFunc);
}

/**
 * Returns the layer configuration with layerName or null if not found
 * @param {Array<module:ol-ishare/profile~LayerGroup>} layerGroups An Array of LayerGroup instances
 * @param {String} layerName Name of layer to find the config for
 * @returns {module:ol-ishare/profile~Layer|null} layerConfig Layer configuration object from
 * the profile or null if not found
 */
function getLayerConfig(layerGroups, layerName) {
  try {
    return getLayerConfigs(layerGroups, [layerName])[0] || null;
  } catch (e) {
    return null;
  }
}

/**
 * Returns the base map configuration with layerName or null if not found
 * @param {Array<BaseMap>} baseMaps Array of base map definitions
 * @param {String} layerName Name of base map to find the config for
 * @returns {BaseMap|null} baseConfig Base map configuration or null if not found
 */
function getBaseConfig(baseMaps, layerName) {
  try {
    return baseMaps.filter(function (baseMap) {
      return baseMap.mapName.toLowerCase() === layerName.toLowerCase();
    })[0];
  } catch (e) {
    return null;
  }
}

/**
 * Return the group definition with the given GUID
 * @param {Array<module:ol-ishare/profile~LayerGroup>} layerGroups An Array of LayerGroup instances
 * @param {String} guid Id of the layer group
 * @returns {module:ol-ishare/profile~LayerGroup|null} Layer group configuration or null if not found
 */
function getGroupConfig(layerGroups, guid) {
  try {
    return layerGroups.filter(function (groupConfig) {
      return groupConfig.guid === guid;
    })[0];
  } catch (e) {
    return null;
  }
}

export {
  getProfile,
  transformLegacyMapSource,
  transformLegacyField,
  transformLegacyLayer,
  transformLegacyBaseMapSource,
  filterLayerConfigs,
  getLayerConfigs,
  getLayerConfig,
  getGroupConfig,
  getBaseConfig,
  getOwsUrl
};
