import * as control_gazetteer from '../esm/control/gazetteer.js';
import * as control_historynavigation from '../esm/control/historynavigation.js';
import * as control_layerswitcher from '../esm/control/layerswitcher.js';
import * as devicelocation from '../esm/devicelocation.js';
import * as history from '../esm/history.js';
import * as http from '../esm/http.js';
import * as info from '../esm/info.js';
import * as interaction_infopopup from '../esm/interaction/infopopup.js';
import * as interaction_measure from '../esm/interaction/measure.js';
import * as interaction_pointquery from '../esm/interaction/pointquery.js';
import * as litemap from '../esm/litemap.js';
import * as logger from '../esm/logger.js';
import * as map from '../esm/map.js';
import * as olutil from '../esm/olutil.js';
import * as profile from '../esm/profile.js';
import * as search from '../esm/search.js';
import * as state from '../esm/state.js';
import * as store from '../esm/store.js';
import * as ui_gazetteer from '../esm/ui/gazetteer.js';
import * as version from '../esm/version.js';
import * as view from '../esm/view.js';

// Build oli namespace for use in browser
var oli = {};
oli.ui = {};
oli.ui.gazetteer = ui_gazetteer;
oli.control = {};
oli.control.gazetteer = control_gazetteer;
oli.control.historynavigation = control_historynavigation;
oli.control.layerswitcher = control_layerswitcher;
oli.history = history;
oli.interaction = {};
oli.interaction.infopopup = interaction_infopopup;
oli.interaction.measure = interaction_measure;
oli.interaction.pointquery = interaction_pointquery;
oli.info = info;
oli.litemap = litemap;
oli.logger = logger;
oli.search = search;
oli.devicelocation = devicelocation;
oli.map = map;
oli.olutil = olutil;
oli.profile = profile;
oli.http = http;
oli.state = state;
oli.store = store;
oli.view = view;
oli.version = version;

export default oli;
