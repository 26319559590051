import Store from './Store.js';
import { debounce } from 'lodash';

function getUrlAndQuerystring(urlAndOrQs) {
  let url = null;
  let querystring = new URLSearchParams();
  if (typeof urlAndOrQs === 'string') {
    try {
      urlAndOrQs = new URL(urlAndOrQs);
    } catch (e) {
      //not valid URL string, is it a querystring?
      const tempURL = new URL('http://dummy/url' + urlAndOrQs); //use to strip paths & #fragment
      querystring = new URLSearchParams(tempURL.searchParams);
    }
  }
  if (urlAndOrQs instanceof Location) {
    urlAndOrQs = new URL(urlAndOrQs);
  }

  if (urlAndOrQs instanceof URL) {
    querystring = new URLSearchParams(urlAndOrQs.searchParams);
    url = new URL(urlAndOrQs);
    url.search = '';
  }
  if (urlAndOrQs instanceof URLSearchParams) {
    querystring = new URLSearchParams(urlAndOrQs);
  }

  return [url, querystring];
}

function onUpdate() {
  this.dispatchEvent({ type: 'updated' });
}

/**
 * Store items in a QueryString
 *
 * This can be used to create links that could, for example, then be used to load an application with a given state.
 * Alternatively the links could be used to submit information to and/or request it from a web service.
 *
 * Can optionally combine the querystring with a URL.
 *
 * @extends module:ol-ishare/store.Store
 *
 * @param {Object} [options] Settings for working with the querystring
 * @param {Boolean} [options.interval=100] Minimum time in milliseconds between emitting events
 * @param {URL|String} [options.baseUrl] A URL to which to append the querystring.
 * @memberof module:ol-ishare/store
 */
class QueryString extends Store {
  #searchParams = new URLSearchParams();
  #baseUrl;
  #onUpdate;

  constructor(options) {
    options = Object.assign({ interval: 100, baseUrl: undefined }, options);
    super(options);
    [this.#baseUrl, this.#searchParams] = getUrlAndQuerystring(options.baseUrl);
    this.#onUpdate = debounce(onUpdate.bind(this), options.interval);
    this.#onUpdate();
  }

  /**
   * A URL with which to combine the querystring.
   *
   * @type {URL|String}
   */
  get baseUrl() {
    let baseURL = this.#baseUrl;
    if (baseURL instanceof URL) {
      baseURL = new URL(this.#baseUrl);
    }
    return baseURL;
  }

  set baseUrl(url) {
    // If a querystring's been passed in by mistake, strip it out.
    [this.#baseUrl] = getUrlAndQuerystring(url);
  }

  /**
   * The full URL created from {@link module:ol-ishare/store.QueryString#baseURL .baseUrl} and {@link module:ol-ishare/store.QueryString#params .params}
   *
   * @type {URL}
   */
  get url() {
    const url = this.#baseUrl;
    if (url instanceof URL) {
      url.search = this.#searchParams.toString();
    }
    return url;
  }

  /**
   * Current querystring parameters
   *
   * @type {URLSearchParams}
   */
  get params() {
    return new URLSearchParams(this.#searchParams);
  }

  // required sub-class methods
  put(dict) {
    return super.put(dict).then((validatedKeys) => {
      let updatedKeys = [];
      const oldParams = this.#searchParams.toString();
      for (const key of validatedKeys) {
        const value = dict[key];
        if (value === undefined) {
          this.#searchParams.delete(key);
        } else {
          this.#searchParams.set(key, JSON.stringify(value));
        }
        updatedKeys.push(key);
      }
      if (this.#searchParams.toString() === oldParams) {
        updatedKeys = [];
      } else {
        this.#onUpdate();
      }
      return updatedKeys;
    });
  }

  fetch(keys) {
    return super.fetch(keys).then((dict) => {
      for (const key in dict) {
        if (this.#searchParams.has(key)) {
          dict[key] = JSON.parse(this.#searchParams.get(key));
        } else {
          dict[key] = undefined;
        }
      }
      return dict;
    });
  }

  discard(keys) {
    return super.discard(keys).then((validatedKeys) => {
      const oldParams = this.#searchParams.toString();
      let deletedKeys = [];
      for (const key of validatedKeys) {
        if (this.#searchParams.has(key)) {
          this.#searchParams.delete(key);
          deletedKeys.push(key);
        }
      }
      if (this.#searchParams.toString() === oldParams) {
        deletedKeys = [];
      } else {
        this.#onUpdate();
      }
      return deletedKeys;
    });
  }
}

export default QueryString;
