/**
 * Creates a map based on a profile and enables reporting on features or locations based on one or more layers in the profile.
 *
 * ```
 * import { LoggerMap } from 'ol-ishare/logger'
 * ```
 * @module ol-ishare/logger
 */
import BaseObject from 'ol/Object';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

import { Circle, Stroke, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { LiteMap } from './litemap.js';
import { PointQuery } from './interaction/pointquery.js';
import { findLayers } from './olutil.js';
import { getInfoAtPoint } from './info.js';
import { getLayerConfigs } from './profile.js';
import { inherits } from './olutil.js';

/* Utility functions */

/**
 * @private
 * @param {Array} sourceArray Array possibly containing duplicate values
 * @returns {Array} Array with duplicate values removed
 */
function deDuplicateArray(sourceArray) {
  var output = sourceArray.concat();
  for (var i = 0; i < output.length; ++i) {
    for (var j = i + 1; j < output.length; ++j) {
      if (output[i] === output[j]) {
        output.splice(j--, 1);
      }
    }
  }
  return output;
}

/**
 * Deep copy an object for when the original should be preserved
 * @private
 * @param {Object} obj - any type of object
 * @returns {Object} a deep copy of the argument
 */
function clone_(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/* Logger-specific code */

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name Location
 * @property {Number} x Horizontal co-ordinate value
 * @property {Number} y Vertical co-ordinate value
 * @property {String} proj Projection of {x} and {y}
 */

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name Context
 * @property {...Array<FeatureCollection>} string One GeoJSON FeatureCollections per context layer
 */

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name CompletedSelection
 * @property {module:ol-ishare/logger~Selection} selection Selection made from target layer
 * @property {String} [description] Additional text for the report, e.g. free-text user description of a fault
 * @property {module:ol-ishare/logger~Context} [context] Context object for report, will be added automatically if not specified. Use only if doing something non-standard with context layers.
 */

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name AbandonedSelection
 * @property {String} [message] Reason for ending selection process
 * @property {Boolean} [isError] Whether the selection has been abandoned because of an error - `message` should have more details
 */

/**
 * Signature for the internal function called when a selection has been made
 * @callback selected
 * @param {module:ol-ishare/logger~CompletedSelection} completedSelection All information about the successful selection
 */

/**
 * Called when attempts to make a selection fail or are deliberately abandoned
 * @callback abandonSelectAction
 * @param {module:ol-ishare/logger~AbandonedSelection} [abandonedSelection] Reason for ending selection process
 */

/**
 * Should present the user with all current selections, the user must then either select one, or abandon the process.
 * @name selectAction
 * @function
 * @param {module:ol-ishare/logger.Location} location The location selected on the map
 * @param {Array<module:ol-ishare/logger.targetLayerDetail>} targetLayerDetails Details for all target layers, plus selections from map query
 * @param {module:ol-ishare/logger.contextLayerDetails} contextLayerDetails All context layer details, plus results of queries per target layer selection
 * @param {module:ol-ishare/logger~selected} selected Function to call to move to the next stage in generating a report
 * @param {module:ol-ishare/logger~abandonSelectAction} abandon Function to call when the selection process should be abandoned
 */

/**
 * Checks and/or filters selections made by the user
 * 
 * If one selection is returned then {@link module:ol-ishare/logger~selected} is called
 * If more than one selection is returned, {@link module:ol-ishare/logger~selectAction} will be called for further steps in the selection process
 * If no results are returned then {@link module:ol-ishare/logger~abandonSelectAction} will be called
 * If a custom reason for abandoning the selection is needed (e.g. the chosen feature doesn't meet some criteria) then
 *   `throw` an {module:ol-ishare/logger~AbandonedSelection} object with the appropriate error message
 *
 * By default this will only check that at least one selection has been made.
 *
 * @name automaticSelectAction
 * @function
 * @param {module:ol-ishare/logger.Location} location The location selected on the map
 * @param {Array<module:ol-ishare/logger.targetLayerDetail>} targetLayerDetails Details for all target layers, plus selections from map query
 * @param {module:ol-ishare/logger.contextLayerDetails} contextLayerDetails All context layer details, plus results of queries per target layer selection
   @returns {Array<module:ol-ishare/logger~Selection>} Results of attempt to perform automatic selection
 */

/**
 * @static
 * @property {module:ol-ishare/logger~selectAction} select Action to perform when multiple selections have been made and have not been resolved automatically
 * @property {module:ol-ishare/logger~autoSelect} autoSelect Action to use initially when the map has been told to attempt a selection
 * @property {module:ol-ishare/logger~abandonSelectAction} abandonSelect Action to perform when selection process should be ended without generating a report
 */
var actions = {
  select: function defaultSelectAction(
    location,
    targetLayerDetails,
    contextLayerDetails,
    select,
    abandon
  ) {
    abandon({
      message: 'Select action not specified',
      error: true
    });
  },
  autoSelect: function (location, targetLayerDetails, contextLayerDetails) {
    var allSelections = targetLayerDetails.reduce(function (a, details) {
      if (details.selections) {
        a = a.concat(details.selections);
      }
      return a;
    }, []);
    return allSelections;
  },
  abandonSelect: function defaultAbandonSelectAction(abandoned) {
    if (abandoned.error === true) {
      console.error('Problem with Logger selection: ' + abandoned.message);
    }
  }
};

/**
 * Create and return an Selection object
 *
 * @class
 * @param {String} profile The name of the iShare profile from which the layer originates
 * @param {String} layer The name of the layer from which the selection was made
 * @param {String} uniqueId Identifier of selected result
 * @param {String} uniqueField Name of the field that contains an identifier for the selection
 * @param {Feature} feature GeoJSON Feature for the selection
 */
var Selection = function (profile, layer, uniqueId, uniqueField, feature) {
  function validate(profile, layer, uniqueId, uniqueField, feature) {
    return !!profile && !!layer && !!uniqueId && !!uniqueField && !!feature;
  }

  this.valid = validate(profile, layer, uniqueId, uniqueField, feature);

  if (this.valid) {
    this.profile = profile;
    this.layer = layer;
    this.uniqueId = uniqueId;
    this.uniqueField = uniqueField;
    this.feature = feature;
  }
  return this;
};
inherits(Selection, Object);

Selection.fromOL = function (olMap, olFeature, profile, layer, uniqueField) {
  var converter = new GeoJSON({
    dataProjection: olMap.projection
  });
  var feature = converter.writeFeatureObject(olFeature);
  var uniqueId = olFeature.get(uniqueField);

  var selection = new Selection(profile, layer, uniqueId, uniqueField, feature);
  return selection;
};

/**
 * Object for bundling all data selected by user
 *
 * @class
 * @param {module:ol-ishare/logger.Location} location The location selected on the map
 * @param {module:ol-ishare/logger~Selection} [selection] The selection made by the user, if any
 * @param {String} [description] Additional text describing the report, if any
 * @param {module:ol-ishare/logger.Context} [context] Context object containing all other selected features, if any
 */
var Report = function (location, selection, description, context) {
  function validate(location, selection, description, context) {
    var valid_location = !!location.x && !!location.y && !!location.projection;
    return valid_location;
  }

  this.valid = validate(location, selection, description, context);

  if (this.valid) {
    this.location = location;
    this.selection = selection;
    this.description = description;
    this.context = context;
    this.active = null;
    this.id = null;
  }
  return this;
};
inherits(Report, Object);

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name layerField
 * @property {String} name Name of field
 * @property {String} displayName User-friendly name for field
 * @property {String} [link] Format of link to external resource for field values
 */

/**
 * Layer information
 *
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name layerDetail
 * @property {String} layer Name of target layer
 * @property {String} displayName User-friendly name for target layer
 * @property {Array<module:ol-ishare/logger.layerField>} fields Field configuration for target layer
 * @property {Object} [metadata] Metadata for layer, if any
 */

/**
 * Results from context layers for a given target layer
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name selectionContexts
 * @property {Object<string, module:ol-ishare/logger.Context>} contexts Contexts found for each result in target layer, keyed by target layer `idField` value
 */

/**
 * Contains context layer definitions and optionally results found when querying target layer(s)
 *
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name contextLayerDetails
 * @property {Array<module:ol-ishare/logger.layerDetail>} layers Information about all context layers
 * @property {Object<string, module:ol-ishare/logger.selectionContexts>} selectionContexts Results for context layers, keyed by target layer name
 */

/**
 * Describes a target layer and optionally contains results from querying the layer
 *
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name targetLayerDetail
 * @property {String} layer Name of target layer
 * @property {String} displayName User-friendly name for target layer
 * @property {String} idField Name of field containing uniquely identifying value for records in target layer data
 * @property {Array<module:ol-ishare/logger.layerField>} fields Field configuration for target layer
 * @property {Object} [metadata] Metadata for layer, if any
 * @property {Array<module:ol-ishare/logger~Selection>} [selections] Results of querying the target layer, if any
 * @property {Array<ol/Feature~Feature>} [olFeatures]: One OpenLayers Feature for each record found when querying the target layer
 * @property {String} [errors] Errors found when querying a layer
 */

/**
 * @typedef {Object}
 * @memberOf module:ol-ishare/logger
 * @name TargetLayerOption
 * @property {String} layer Name of layer - {@link module:ol-ishare/profile~Layer Layer}.layerName
 * @property {String} idField Name of field containing unique identifier to use for selection(s) in a report
 */

/**
 * @typedef {Object} 
 * @memberOf module:ol-ishare/logger
 * @name LoggerMapOptions
 * @property {module:ol-ishare/logger.TargetLayerOption|Array<module:ol-ishare/logger.TargetLayerOption>} [reportOn] Details of layer(s) that will be used to log reports against, if required. If valid layers are configured that are not in `mapOptions.layers` then they will be added to the map.
 * @property {Array<String>} [contextLayers] Names of layers from which to include details in a Report. If no context layers are set, then there will be no context layer data. If valid layers are set that are not set in `mapOptions`, then they will be added to the map.
 * @property {module:ol-ishare/logger~selectAction} [selectAction] Function to allow user to select from all results from a target layer
 * @property {module:ol-ishare/logger~abandonSelectAction} [abandonSelectAction] Called when one of the other select actions stops the selection process without making a report
 * @property {ol/layer/Vector} [overLayer] Predefined layer to use for displaying highlighted selections.

 */

/**
 * A Logger map object creates a dynamic OpenLayers map and adds the ability to create a report at a specified location, and optionally against a given feature.
 *
 * @class
 * @param {module:ol-ishare/litemap~MapOptions} mapOptions General map options
 * @param {module:ol-ishare/logger.LoggerMapOptions} loggerOptions Logger-specific options
 */
var LoggerMap = function (mapOptions, loggerOptions) {
  var ol_opts = Object.assign(
    {
      interactions: LiteMap.defaultInteractions()
    },
    mapOptions ? mapOptions.map : {}
  );

  loggerOptions = Object.assign(
    {
      selectAction: actions.select,
      automaticSelectAction: actions.autoSelect,
      abandonSelectAction: actions.abandonSelect
    },
    loggerOptions
  );

  var targetLayers = [];
  var contextLayerNames = [];
  if (Array.isArray(loggerOptions.reportOn)) {
    targetLayers = loggerOptions.reportOn.map(function (l) {
      return clone_(l);
    });
  } else if (loggerOptions.reportOn) {
    targetLayers = clone_(loggerOptions.reportOn);
  }
  var targetLayerNames = targetLayers.map(function (l) {
    return l.layer;
  });
  if (Array.isArray(loggerOptions.contextLayers)) {
    // remove target layers from context, so they don't appear twice in the report
    contextLayerNames = loggerOptions.contextLayers.filter(function (item) {
      return targetLayerNames.indexOf(item) < 0;
    });
  }

  if (loggerOptions.overLayer) {
    this.overlay_ = loggerOptions.overLayer;
  } else {
    this.overlay_ = new VectorLayer({
      source: new VectorSource({
        wrapX: false
      })
    });
  }

  var queryInteraction = new PointQuery({
    markPoint: true,
    markerPersist: true,
    queryLayers: targetLayerNames
  });
  this.url_ = mapOptions.iShareUrl;
  this.profile_ = mapOptions.profile;

  this.select_ = loggerOptions.selectAction;
  this.autoSelect_ = loggerOptions.automaticSelectAction;
  this.abandonSelect_ = loggerOptions.abandonSelectAction;

  queryInteraction.on(
    'results',
    function (evt) {
      onResults_.call(this, evt);
    }.bind(this)
  );
  ol_opts.interactions.push(queryInteraction);

  mapOptions.map = ol_opts;

  // combine layers - in case context and target layers missed from map options
  mapOptions.layers = deDuplicateArray(
    (mapOptions.layers || []).concat(targetLayerNames, contextLayerNames)
  );

  var lite = LiteMap.call(this, mapOptions);
  lite.on(
    'load',
    function (evt) {
      var targetLayerConfigs = getLayerConfigs(
        lite.profile.layerGroups,
        targetLayerNames
      );
      this.targetLayers_ = targetLayerConfigs.reduce(function (layers, config) {
        var index = targetLayerNames.indexOf(config.layerName);
        layers[index]['config'] = config;
        return layers;
      }, targetLayers);

      this.contextConfigs_ = getLayerConfigs(
        lite.profile.layerGroups,
        contextLayerNames
      );
    }.bind(this)
  );

  return this;
};
inherits(LoggerMap, LiteMap);

/**
 * Highlights the feature of a selection on the target layer using a coloured outline
 * @param {Selection} selection The selection object to highlight
 * @param {Array<Number>} [rgb] Array of three RGB numbers (0-255) indicating the highlight colour
 */
LoggerMap.prototype.highlightSelection = function (selection, rgb) {
  var olFeature = this.featureForSelection(selection);
  if (olFeature) {
    createFeatureHighlight_(this.map, this.overlay_, olFeature, rgb);
  }
};

/**
 * Zooms the map so that all provided selections are in view
 * @param {Array<Selection>} selections Array of selection objects
 * @param {ol/View~FitOptions} options fit options
 */
LoggerMap.prototype.zoomToSelections = function (selections, options) {
  var olFeatures = selections.reduce(
    function (features, selection) {
      features.push(this.featureForSelection(selection));
      return features;
    }.bind(this),
    []
  );
  this.zoomToFeatures(olFeatures, options);
};

/**
 * Creates an OpenLayers Feature for a selecton
 * @param {Selection} selection The selection object
 * @returns {ol/Feature~Feature} Feature
 */
LoggerMap.prototype.featureForSelection = function (selection) {
  if (selection) {
    var geoJSON = new GeoJSON({
      dataProjection: this.map.getView().getProjection()
    });
    var olFeature = geoJSON.readFeature(selection.feature);
    return olFeature;
  }
  return null;
};

/**
 * Clears highlighted features from the map
 */
LoggerMap.prototype.clearHighlights = function () {
  clearFeatures_(this.overlay_);
};

/**
 * Clears any highlighted target features or query coordinates
 */
LoggerMap.prototype.clearSelection = function () {
  this.map.getInteractions().forEach(function (interaction) {
    if (interaction instanceof PointQuery) {
      interaction.clearMarker();
    }
  });
  this.clearHighlights();
};

LoggerMap.prototype.autoSelect_ = null;

/**
 * @private
 */
LoggerMap.prototype.select_ = null;

/**
 * @private
 */
LoggerMap.prototype.abandonSelect_ = null;

/**
 * @private
 */
LoggerMap.prototype.contextConfigs_ = [];

/**
 * @private
 */
LoggerMap.prototype.targetLayers_ = [];

/**
 * Query other layers for a given OpenLayers Feature
 * @private
 * @param {ol/Map~Map} map Map instance to query
 * @param {Array<Number>} coordinates X, Y pairing of location to query
 * @param {Array<String>} layerNames Context layer names to query
 * @returns {Promise} Promise that resolves to the features found on all the context layers
 */
function getContextFromCoords_(map, coordinates, layerNames) {
  if (layerNames.length === 0) {
    return new Promise(function (resolve) {
      resolve(null);
    });
  }
  var contextLayers = findLayers(map.getLayerGroup(), function (layer) {
    return layerNames.indexOf(layer.get('iShare:layerName')) > -1;
  });
  return getInfoAtPoint(map, contextLayers, coordinates).then(function (
    allResults
  ) {
    var repromise;
    var thereAreErrors = allResults.errors.some(function (item) {
      return item !== null;
    });
    if (thereAreErrors) {
      repromise = Promise.reject(allResults.errors);
    } else {
      var results = allResults.infoLayers.reduce(function (r, layer, i) {
        var collection = allResults.collections[i];
        r[layer.get('iShare:layerName')] = collection;
        return r;
      }, {});
      repromise = Promise.resolve(results);
    }
    return repromise;
  });
}
/**
 * Create a new vector feature that highlights the supplied feature in the following ways:
 * * Polygon: bright pink outline using feature geometry
 * * Linestring: bright pink line using feature geometry
 * * Point: bright pink circle of 15px radius around point
 * Note: colour can be overriden using the 'rgb' parameter
 * @private
 * @param {ol/Map~Map} map Map to which the highlight layer should be added
 * @param {ol/layer~Layer} layer Layer to use for creating the highlight
 * @param {ol/feature~Feature} feature The feature to be highlighted
 * @param {Array<Number>} [rgb] Array of three RGB numbers (0-255)
 */
function createFeatureHighlight_(map, layer, feature, rgb) {
  var geometry = feature.getGeometry().clone();
  var type = geometry.getType();
  var highlight = new Feature({ geometry: geometry });
  var stroke = new Stroke({
    color: rgb || [255, 86, 177],
    width: 2
  });
  switch (type) {
    case 'Point':
    case 'MultiPoint':
      highlight.setStyle(
        new Style({
          image: new Circle({
            radius: 15,
            stroke: stroke
          })
        })
      );
      break;
    case 'Line':
    case 'MultiLine':
    case 'Polygon':
    case 'MultiPolygon':
      highlight.setStyle(
        new Style({
          stroke: stroke
        })
      );
      break;
    default:
      break;
  }
  clearFeatures_(layer);
  layer.setMap(map);
  layer.getSource().addFeature(highlight);
}

/**
 * Clear all features from a layer
 * @private
 * @param {ol/layer~Layer} layer Layer to clear
 */
function clearFeatures_(layer) {
  if (layer) {
    layer.getSource().clear(false);
    layer.setMap(null);
  }
}

/**
 * Query other layers for a given OpenLayers Feature
 * @private
 * @param {ol/Map~Map} map Map instance to query
 * @param {Array<Number>} query_coords XgetContextFromCoords_, Y pairing of the original point selected on the map
 * @param {ol/Feature~Feature} olFeature  OpenLayers feature for which context is required
 * @param {Array<String>} layerNames Context layer names to query
 * @returns {Promise} Promise that resolves to the features found on all the context layers
 */
function getFeatureContext_(map, query_coords, olFeature, layerNames) {
  if (layerNames.length === 0) {
    return new Promise(function (resolve) {
      resolve(null);
    });
  }
  var geometry = olFeature.getGeometry();
  var type = geometry.getType();

  var coordinates;
  switch (type) {
    case 'Point':
    case 'MultiPoint':
      coordinates = geometry.getFirstCoordinate();
      break;
    case 'Line':
    case 'MultiLine':
    case 'Polygon':
    case 'MultiPolygon':
      coordinates = geometry.getClosestPoint(query_coords);
      break;
    default:
      break;
  }
  return getContextFromCoords_(map, coordinates, layerNames);
}

/**
 * Query a map for context of all target layer results
 * @private
 * @param {ol/Map~Map} map Map instance to query
 * @param {Array<Number>} query_coords X, Y pairing of the original point selected on the map
 * @param {Object} contextConfigs Context layer configurations
 * @param {Object} targetLayersAndResults target layer configs and query results
 * @returns {Promise} Promise that resolves to an object containing context layer details and results keyed by selections
 */
function gatherContext_(
  map,
  query_coords,
  contextConfigs,
  targetLayersAndResults
) {
  if (contextConfigs.length === 0) {
    return new Promise(function (resolve) {
      resolve(null);
    });
  }
  var featureConverter = new GeoJSON({
    dataProjection: map.getView().getProjection()
  });
  var allTargetLayerResults = targetLayersAndResults.reduce(function (
    af,
    detail
  ) {
    var layerResults = detail.selections.map(function (a, i) {
      return { selection: a, olFeature: detail.olFeatures[i] };
    });
    return af.concat(af, layerResults);
  },
  []);

  var layerNames = contextConfigs.map(function (config) {
    return config.layerName;
  });

  var layerInfo = contextConfigs.map(function (config) {
    var info = {
      layer: config.layerName,
      displayName: config.displayName,
      fields: config.fields,
      metadata: config.metadata
    };
    return info;
  });
  var contextDetails = {
    layers: layerInfo
  };
  var targetLayerCalls = allTargetLayerResults.map(function (
    targetLayerResult
  ) {
    return getFeatureContext_(
      map,
      query_coords,
      targetLayerResult.olFeature,
      layerNames
    ).then(function (results) {
      var contextResults = {};
      Object.keys(results).forEach(function (layerName) {
        contextResults[layerName] = featureConverter.writeFeaturesObject(
          results[layerName]
        );
      });
      return {
        layer: targetLayerResult.selection.layer,
        uniqueId: targetLayerResult.selection.uniqueId,
        context: contextResults
      };
    });
  });
  return Promise.all(targetLayerCalls).then(function (results) {
    contextDetails.selectionContexts = results.reduce(function (obj, result) {
      obj[result.layer] = obj[result.layer] || {};
      obj[result.layer][result.uniqueId] = result.context;
      return obj;
    }, {});
    return contextDetails;
  });
}
/**
 * Actions taken on receiving results from the query interaction
 * @private
 * @param {ol/Events/Event~BaseEvent} evt Event dispatched by map Interaction
 */
function onResults_(evt) {
  var projection = evt.map.getView().getProjection();
  var location = {
    x: evt.coordinate[0],
    y: evt.coordinate[1],
    projection: projection.getCode()
  };

  var results = {};
  for (var i = 0; i < evt.infoLayers.length; i++) {
    var l = evt.infoLayers[i];
    results[l.get('iShare:layerName')] = {
      olFeatures: evt.featureCollections[i],
      errors: evt.errors[i]
    };
  }

  var profileName = this.profile;
  var targetLayerDetails = this.targetLayers_.map(function (layer) {
    var layerResults = results[layer.layer];
    var detail = {
      layer: layer.layer,
      displayName: layer.config.displayName,
      idField: layer.idField,
      fields: layer.config.fields,
      metadata: layer.config.metadata,
      selections: null,
      olFeatures: null,
      errors: null
    };
    if (layerResults.olFeatures.length) {
      detail.errors = layerResults.errors;
      if (!detail.errors) {
        detail.olFeatures = layerResults.olFeatures;
        detail.selections = detail.olFeatures.map(function (olFeature) {
          return Selection.fromOL(
            evt.map,
            olFeature,
            profileName,
            detail.layer,
            detail.idField
          );
        });
      }
    }
    return detail;
  });

  function makeReport(completed) {
    var selection = completed.selection;
    var description = completed.description;
    var context = completed.context;
    var loc = clone_(completed.location);
    if (selection) {
      var point;
      if (selection.feature.geometry.type === 'Point') {
        point = selection.feature.geometry.coordinates;
      } else if (selection.feature.geometry.type === 'MultiPoint') {
        point = selection.feature.geometry.coordinates[0];
      }
      if (point) {
        loc.x = point[0];
        loc.y = point[1];
      }
    }
    if (context) {
      this.contextConfigs_.forEach(function (config) {
        if (context[config.layerName] && context[config.layerName].features) {
          context[config.layerName].title = config.displayName;
          context[config.layerName].properties = config.fields;
          context[config.layerName].metadata = Object.keys(config.metadata)
            .length
            ? config.metadata
            : null;
        }
      });
    }
    /**
     * Map clicked, target layer feature selected
     *
     * @event module:ol-ishare/logger#report
     * @type {Object}
     * @property {string} type `'report'`
     * @property {module:ol-ishare/logger~Report} report Report object
     *
     */
    this.dispatchEvent({
      type: 'report',
      report: new Report(loc, selection, description, context)
    });
  }

  var targetLayersAndResults = targetLayerDetails.filter(function (detail) {
    return !!detail.selections;
  });

  if (this.targetLayers_.length === 0) {
    // no selection needed so proceed with simple location report
    var featureConverter = new GeoJSON({
      dataProjection: this.map.getView().getProjection()
    });
    getContextFromCoords_(
      this.map,
      evt.coordinate,
      this.contextConfigs_.map(function (config) {
        return config.layerName;
      })
    )
      .then(function (results) {
        var context = {};
        if (results) {
          Object.keys(results).forEach(function (layerName) {
            context[layerName] = featureConverter.writeFeaturesObject(
              results[layerName]
            );
          });
        }
        return {
          selection: null,
          description: '',
          context: context,
          location: location
        };
      })
      .then(makeReport.bind(this));
  } else if (targetLayersAndResults.length > 0) {
    var autoSelect_ = this.autoSelect_;
    var select_ = this.select_;
    var abandonSelect_ = this.abandonSelect_;

    gatherContext_(
      this.map,
      evt.coordinate,
      this.contextConfigs_,
      targetLayersAndResults
    )
      .then(function (results) {
        return new Promise(function (resolve, reject) {
          var allSelections = autoSelect_(
            clone_(location),
            clone_(targetLayerDetails),
            clone_(results)
          );
          if (allSelections.length === 0) {
            reject({ message: 'Nothing selected', error: false });
          } else if (allSelections.length === 1) {
            resolve({ selection: allSelections[0] });
          } else {
            resolve(
              new Promise(function (resolve, reject) {
                select_(
                  clone_(location),
                  targetLayerDetails,
                  results,
                  resolve,
                  reject
                );
              })
            );
          }
        }).then(function (completed) {
          var context = completed.context;
          if (!context && results) {
            context =
              results.selectionContexts[completed.selection.layer][
                completed.selection.uniqueId
              ];
          }
          return {
            selection: completed.selection,
            description: completed.description,
            context: context,
            location: location
          };
        });
      })
      .then(makeReport.bind(this))
      .catch(abandonSelect_);
  } else {
    /**
     * Map clicked, target layer feature not selected
     *
     * @event module:ol-ishare/logger#nothingSelected
     * @type {Object}
     * @property {string} type `'nothingSelected'`
     *
     */
    this.dispatchEvent({
      type: 'nothingSelected'
    });
  }
}

/**
 * Manage report data stored in iShare, for a given profile
 *
 * Requires iShare v5.8.22 or higher
 *
 * @class
 * @param {String} iShareUrl Base URL for iShare Web app
 * @param {String} profileName iShare profile to use for Logger
 * @param {String} layerName Layer in profile that contains previous reports
 */
var ReportsManager = function (iShareUrl, profileName, layerName) {
  BaseObject.call(this);
  this.baseUrl_ = iShareUrl;
  var l = this.baseUrl_.length;
  if (this.baseUrl_.substring(l - 1, l) !== '/') {
    this.baseUrl_ = this.baseUrl_ + '/';
  }
  this.profile_ = profileName;
  this.layer_ = layerName;
  return this;
};

inherits(ReportsManager, BaseObject);

/**
 * Build a request for iShare FaultLogging.aspx
 * @private
 * @param {'add'|'remove'} operation Maps to ReportsManager methods
 * @param {Object} opts Optional parameters
 * @returns {Request} Unique iShare reference to the report
 */
ReportsManager.prototype.buildFaultLoggingRequest_ = function (
  operation,
  opts
) {
  var reportsUrl = this.baseUrl_ + 'reports.ashx';
  var request;
  var requestOpts = {
    method: 'POST',
    body: new FormData()
  };
  requestOpts.body.set('mapsource', this.profile_);
  requestOpts.body.set('layer', this.layer_);
  for (var key in opts) {
    requestOpts.body.set(key, opts[key]);
  }
  request = new Request(reportsUrl, requestOpts);
  return request;
};

/**
 * Store the details of a Logger or location selection
 * @param {module:ol-ishare/logger~Report} report Report object
 * @returns {Promise.String} Promise object that resolves to a new Report with assigned ID and active state
 */
ReportsManager.prototype.add = function (report) {
  var opts = {
    action: 'add',
    x: report.location.x,
    y: report.location.y,
    description: report.description || ''
  };
  if (report.selection) {
    opts.selectionId = report.selection.uniqueId;
    opts.selectionIdField = report.selection.uniqueField;
  }
  var request = this.buildFaultLoggingRequest_('add', opts);

  var dataPromise = fetch(request).then(function (response) {
    return response.json();
  });
  return dataPromise.then(function (data) {
    return new Promise(function (resolve, reject) {
      var updatedReport = clone_(report);
      updatedReport.id = data.reportid;
      updatedReport.active = true;
      resolve(updatedReport);
    });
  });
};

/**
 * Removes a report from iShare storage
 * @param {module:ol-ishare/logger~Report} report Report object
 * @return {module:ol-ishare/logger~Report} Report object
 */
ReportsManager.prototype.archive = function (report) {
  var opts = {
    action: 'archive',
    reportid: report.id
  };
  var request = this.buildFaultLoggingRequest_('archive', opts);

  var dataPromise = fetch(request).then(function (response) {
    return response.json();
  });
  return dataPromise.then(function (data) {
    return new Promise(function (resolve, reject) {
      var updatedReport = clone_(report);
      updatedReport.active = data.active;
      resolve(updatedReport);
    });
  });
};

export { LoggerMap, ReportsManager, actions };
