/**
 * Handy utilities to make working with OpenLayers easier
 * @module ol-ishare/olutil
 */

/**
 * Filter function signatures for `ol-ishare/findLayers`.
 * @name findLayersFilter
 * @function
 * @param {ol/layer/Base~BaseLayer} layer The candidate layer
 * @param {ol/layer/Group~LayerGroup} group The group that the layer belongs to
 * @returns {Boolean} Whether the layer should be included in the results
 */

/**
 * Find layers that are a decendent of the given `layerGroup` by testing each with a `filter`
 * @param {ol/layer/Group~LayerGroup} layerGroup The layer group to search recursively (commonly `map.getLayerGroup()`)
 * @param {module:ol-ishare/olutil~findLayersFilter} filter Function called for each layer found to determine whether to include it in the returned array
 * @returns {Array<ol/layer/Base~BaseLayer>} Array of layers which satisfy the filter
 */
function findLayers(layerGroup, filter) {
  var layers = [];
  function find(lyrGroup) {
    var ls = lyrGroup.getLayers().getArray();
    for (var n = 0, l; n < ls.length; n++) {
      l = ls[n];
      if (filter(l, lyrGroup)) {
        layers.push(l);
      }
      if (l.getLayers) {
        find(l);
      }
    }
  }
  find(layerGroup);
  return layers;
}

/**
 * Find all visible layers in a given layer group
 * @param {ol/layer/group~Group} layerGroup The layer group to search recursively
 * @returns {Array<ol/layer/Base~BaseLayer>} All layers currently set to visible
 */
function getVisibleLayers(layerGroup) {
  return findLayers(layerGroup, function (lyr) {
    return !lyr.getLayers && lyr.getVisible();
  });
}

/**
 * Find the first layer that is a decendant of the given `layerGroup` with the given iShare layerName.
 * @param {ol/layer/group~Group} layerGroup The layer group to search recursively (commonly `map.getLayerGroup()`)
 * @param {String} layerName The iShare layerName to search for (as defined in the profile)
 * @returns {ol/layer/Base~BaseLayer|null} First layer with the given layerName or null if not found
 */
function getLayerByName(layerGroup, layerName) {
  return (
    findLayers(layerGroup, function (lyr) {
      return lyr.get('iShare:layerName') === layerName;
    })[0] || null
  );
}

/**
 * Find the first group that is a decendant of the given `layerGroup` with the given iShare GUID (groups have GUIDs not names).
 * @param {ol/layer/group~Group} layerGroup The layer group to search recursively (commonly `map.getLayerGroup()`)
 * @param {String} guid The iShare group GUID to search for (as defined in the profile)
 * @returns {ol/layer/Group~LayerGroup|null} First group with the given GUID, or null if not found
 */
function getGroupByGuid(layerGroup, guid) {
  return (
    findLayers(layerGroup, function (lyr) {
      return lyr.get('iShare:guid') === guid;
    })[0] || null
  );
}

/**
 * Gets the Group that a given Layer is contained within
 * @param {ol/layer/Group~LayerGroup} layerGroup The layer group to search recursively (commonly `map.getLayerGroup()`)
 * @param {ol/layer/Base~BaseLayer} layer The layer that the Group your trying to find is a child of
 * @returns {ol/layer/Base~BaseLayer|null} Group containing the layer or null if not found
 */
function getGroupContainingLayer(layerGroup, layer) {
  var group = null;
  findLayers(layerGroup, function (lyr, grp) {
    if (lyr === layer) {
      group = grp;
      return true;
    }
    return false;
  });
  return group;
}

/**
 * Returns the first interaction in the interactions Collection of a given type
 * @param {ol/Collection<ol/interaction/Interaction>} interactions Collection of interactions to search
 * @param {Class} type Type of the interaction to find via `instanceof`
 * @returns {ol/interaction/Interaction~Interaction|null} The first interaction of the specified type or null if not found
 */
function getInteractionByType(interactions, type) {
  return (
    interactions.getArray().find(function (interaction) {
      return interaction instanceof type;
    }) || null
  );
}

/**
 * Returns iShare basemap layers from OpenLayers map
 * @param {ol/Map~Map} map Map instance created from iShare profile using LiteMap or similar
 * @returns {Array<ol/layer/Base~BaseLayer>} All basemap layers
 */
function getBaseLayers(map) {
  var baseLayerGroup = getGroupByGuid(map, 'basemaps');
  return baseLayerGroup.getLayersArray();
}

/**
 * Returns first iShare basemap layer currently shown on the map
 *
 * If more than one basemap is shown then use getBasemapLayers and filter on .getVisible() to return them all
 * @param {ol/Map~Map} map Map instance created from iShare profile using LiteMap or similar
 * @returns {ol/layer/Base~BaseLayer} Current basemap layer
 */
function getCurrentBaseLayer(map) {
  var basemapLayers = getBaseLayers(map);
  return basemapLayers.filter(function (l) {
    return l.getVisible();
  })[0];
}

/**
 * Inherit the prototype methods from one constructor into another.
 *
 * Usage:
 *
 *     function ParentClass(a, b) { }
 *     ParentClass.prototype.foo = function(a) { }
 *
 *     function ChildClass(a, b, c) {
 *       // Call parent constructor
 *       ParentClass.call(this, a, b);
 *     }
 *     inherits(ChildClass, ParentClass);
 *
 *     var child = new ChildClass('a', 'b', 'see');
 *     child.foo(); // This works.
 *
 * @param {!Function} childCtor Child constructor.
 * @param {!Function} parentCtor Parent constructor.
 */
function inherits(childCtor, parentCtor) {
  childCtor.prototype = Object.create(parentCtor.prototype);
  childCtor.prototype.constructor = childCtor;
}

export {
  findLayers,
  getLayerByName,
  getGroupByGuid,
  getGroupContainingLayer,
  getInteractionByType,
  getVisibleLayers,
  getBaseLayers,
  getCurrentBaseLayer,
  inherits
};
