import { Observable } from 'ol';

function requireOverride(cls, instance, methods) {
  const proto = Object.getPrototypeOf(instance);
  const superProto = cls.prototype;
  const allMethods = Object.getOwnPropertyNames(superProto).filter((name) => {
    return typeof superProto[name] === 'function';
  });
  const requiredMethods =
    methods instanceof Array
      ? allMethods.filter((name) => methods.includes(name))
      : allMethods;
  const missing = requiredMethods.find((name) => !proto.hasOwnProperty(name));
  if (missing)
    throw new TypeError(
      `'${instance.constructor.name}.${missing}()' is required, but not implemented.`
    );
}

/**
 * Store base class
 *
 * Common interface for storing data as key-value pairs. Keys must be {@link String strings}.
 *
 * All instance methods are asynchronous to allow for sub-classes that make use of web services, or other back-end that
 *     introduces latency
 *
 * Initially implemented to provide interchangeable storage for {@link module:ol-ishare/state.State ol-ishare State} instances, this has
 *     been made more generic so that it could also be used wherever a similar requirement exists for being able to switch
 *     between two storage mechanisms, or where a Store sub-class has already been created that adds value above working
 *     with such a mechanism directly.
 *
 *
 *
 * @param {Object} [options] Optional settings for Store (none currently defined for base class)
 * @memberof module:ol-ishare/store
 */
class Store extends Observable {
  constructor(options) {
    options = Object.assign({}, options);
    super();
    requireOverride(Store, this); //all methods must be overriden, but State implements to provide consistent base for sub-classes
  }

  /**
   * Put items in storage
   *
   * @abstract
   * @param {Object} dict Items to store, keys/property names must be strings. Values must be serializable to strings.
   * @returns {Promise} Resolves to the keys of values that were successfully stored.
   */
  put(dict) {
    const sanitizedDict = this.constructor.sanitizeValues(dict);
    return Promise.resolve(Object.keys(sanitizedDict));
  }

  /**
   * Fetch items from storage
   *
   * @abstract
   * @param {Array<String>} keys Names of items to fetch from storage.
   * @returns {Promise} Resolves to an object with properties for each valid key, and the stored item with that name as the value.
   */
  fetch(keys) {
    const validatedDict = {};
    for (const key of this.constructor.validateKeys(keys)) {
      validatedDict[key] = undefined;
    }
    return Promise.resolve(validatedDict);
  }

  /**
   * Discard items in storage
   *
   * @abstract
   * @param {Array<String>} keys Names of items to discard.
   * @returns {Promise} Resolves to an array of names of items that were successfully deleted.
   */
  discard(keys) {
    const validatedKeys = this.constructor.validateKeys(keys);
    return Promise.resolve(validatedKeys);
  }

  /**
   * Validate key names
   *
   * Ensures that keys are strings and ignores those that aren't.
   *
   * This method can be overridden in sub-classes if stores have different and/or additional validation requirements.
   * @param {Array<String>|String} keys Names of properties to validate.
   * @returns {Array<String>} Keys that were successfully validated.
   */
  static validateKeys(keys) {
    const validatedKeys = [];
    if (!(keys instanceof Array)) {
      keys = [keys];
    }
    for (const key of keys) {
      if (typeof key === 'string' || key instanceof String) {
        validatedKeys.push(String(key));
      } else {
        console.warn('Cannot use non-string key in ol-ishare Store');
      }
    }
    return validatedKeys;
  }

  /**
   * Sanitize property values
   *
   * Ensures that values are able to be transformed to JSON strings and back again (or are undefined),
   *     ignores those that aren't.
   *
   * This method can be overridden in sub-classes if stores have different and/or additional validation requirements.
   * @param {Object} dict Object containing values to validate.
   * @returns {Object} Contains only those properties which have values that can be converted,
   *    and sanitized copies of the original values.
   */
  static sanitizeValues(dict) {
    const sanitizedDict = {};
    for (const [key, value] of Object.entries(dict)) {
      try {
        const sanitized =
          value === undefined ? undefined : JSON.parse(JSON.stringify(value));
        sanitizedDict[key] = sanitized;
      } catch {
        console.warn(
          'ol-ishare Store cannot sanitize value ' +
            String(value) +
            ' for key ' +
            key
        );
      }
    }
    return sanitizedDict;
  }
}

export default Store;
