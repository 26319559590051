/**
 * Working with iShare profile configuration relating to the map view.
 * @module ol-ishare/view
 */

// TODO: Don't assume units are meters
// TODO: Don't assume DPI is 72

// Constants used to covert for scale to resolution and back
var DOTS_PER_INCH = 72;
var INCHES_PER_METER = 2.54 / (DOTS_PER_INCH * 100);

/**
 * Convert a scale value to its corresponding resolution.
 * Assumes units are in meters and fixed DPI.
 * @param {Number} scale Map scale (1: scale)
 * @returns {Number} resolution Map resolution (pixels per meter)
 */
function scaleToResolution(scale) {
  return scale * INCHES_PER_METER;
}

/**
 * Convert a resolution value to its corresponding scale.
 * Assumes units are in meters and fixed DPI.
 * @param {Number} resolution Map resolution (pixels per meter)
 * @returns {scale} scale Map scale (1: scale)
 */
function resolutionToScale(resolution) {
  return resolution / INCHES_PER_METER;
}

/**
 * Find the closest zoom level for a given map width in meters.
 * @param {Number} meters The width across the map in meters that we are finding a zoom
 * level for.
 * @param {Number} pixels The current width of the map in pixels.
 * @param {Array} resolutions An ordered list of resolutions that correspond with
 * the zoom levels for the map.
 * @returns {Number} Nearest zoom level to give map width
 */
function nearestZoom(meters, pixels, resolutions) {
  var items = resolutions.map(function (res, idx) {
    var item = { res: res, zoom: idx, meters: res * pixels };
    item.diff = Math.abs(item.meters - meters);
    return item;
  });
  var nearest = items.sort(function (a, b) {
    return a.diff - b.diff;
  });
  return nearest[0].zoom;
}

export {
  scaleToResolution,
  resolutionToScale,
  nearestZoom,
  DOTS_PER_INCH,
  INCHES_PER_METER
};
