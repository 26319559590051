/**
 * ```
 * import { PointQuery } from 'ol-ishare/interaction/pointquery';
 * ```
 * @module ol-ishare/interaction/PointQuery
 */
import Feature from 'ol/Feature';
import Interaction from 'ol/interaction/Interaction';
import Point from 'ol/geom/Point';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { easeOut } from 'ol/easing';
import { getInfoAtPoint, getInfoLayers } from '../info.js';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';

var markerStyle = new Style({
  image: new CircleStyle({
    radius: 8,
    stroke: new Stroke({
      color: 'rgba(128, 128, 255, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(128, 128, 255, 0.4)'
    })
  })
});

/**
 * Create 'ping' animation at a given point on a specified map layer
 * Currently requires a feature to be added to the map to trigger the animations
 * @param {ol/Map~Map} map OpenLayers map
 * @param {ol/layer~Layer} layer Layer to apply the ping to
 * @param {ol/geom/Point~Point} point OpenLayers point geometry for location
 * @param {Number} duration Time in milliseconds for the animation to last
 * @param {Array<Number>} rgb [red, green, blue] values for the colour of the ping
 * @returns {Promise} returns Promise to allow chaining of actions after animation finishes
 */
function ping(map, layer, point, duration, rgb) {
  return new Promise(function (resolve) {
    var start = new Date().getTime();
    var listenerKey = layer.on('postrender', animate);
    layer.getSource().once('removefeature', function (evt) {
      unByKey(listenerKey);
    });
    duration = duration || 1000;
    rgb = rgb || [0, 255, 0];
    function animate(event) {
      var vectorContext = getVectorContext(event);
      var frameState = event.frameState;
      var flashGeom = point.clone();
      var elapsed = frameState.time - start;
      var elapsedRatio = elapsed / duration;
      var radius = easeOut(elapsedRatio) * 25 + 5;
      var opacity = easeOut(1 - elapsedRatio);

      var style = new Style({
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: rgb.concat(opacity),
            width: 0.25 + opacity
          })
        })
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {
        unByKey(listenerKey);
        resolve(event);
      } else {
        // tell OpenLayers to continue postrender animation
        map.render();
      }
    }
  });
}

/**
 * PointQuery interaction
 * @class
 * @classdesc Returns location and iShare Layer features at a point specified by a click
 * @param {Object} opt_options Interaction options
 * @param {Boolean} opt_options.markPoint Mark position of point queried on the map
 * @param {ol/style/Style} opt_options.markerStyle OpenLayers Style object for marker
 * @param {Boolean} opt_options.markerPersist Whether the marker remains on the map until
 *    another query is made or PointQuery.clearMarker() is called
 * @param {Array<String>} opt_options.queryLayers Only query the layers in this list
 * @param {ol/layer/Vector} opt_options.overLayer The layer on which the query point marker should be drawn
 */
var PointQuery = (function (Interaction) {
  function PointQuery(opt_options) {
    opt_options = opt_options || {};
    Interaction.call(this, {
      handleEvent: this.handleEvent
    });
    this.queryLayers_ = opt_options.queryLayers || null;
    if (opt_options.markPoint === true) {
      this.markerOptions_ = {
        style: opt_options.markerStyle || markerStyle,
        persist: opt_options.markerPersist || false
      };
      if (opt_options.overLayer) {
        this.overlay_ = opt_options.overLayer;
      } else {
        this.overlay_ = new VectorLayer({
          zIndex: 10,
          source: new VectorSource({
            wrapX: false
          })
        });
      }
    }
    this.requestCounter = 0;
  }

  if (Interaction) {
    PointQuery.__proto__ = Interaction;
  }
  PointQuery.prototype = Object.create(Interaction && Interaction.prototype);
  PointQuery.prototype.constructor = PointQuery;

  return PointQuery;
})(Interaction);

/**
 * Handles the {@link ol/MapBrowserEvent map browser event} (if it was a
 * singleclick) and requests info. Generally not called by user code.
 * @param {ol/MapBrowserEvent} mapBrowserEvent Map browser event.
 * @return {boolean} `false` to stop event propagation.
 * @this {module:ol-ishare/interaction/PointQuery}
 */
PointQuery.prototype.handleEvent = function handleEvent(mapBrowserEvent) {
  var stopEvent = false;
  var map_ = this.getMap();
  if (map_) {
    if (mapBrowserEvent.type == 'singleclick') {
      if (this.overlay_) {
        this.addMarker_(mapBrowserEvent.coordinate);
      }
      this.requestInfo(map_, mapBrowserEvent.coordinate);
      mapBrowserEvent.preventDefault();
      stopEvent = true;
    }
  }
  return !stopEvent;
};

/**
 * Remove the interaction from its current map and attach it to the new map.
 * @param {ol/PluggableMap} map Map.
 */
PointQuery.prototype.setMap = function (map) {
  this.cleanUpMap_();
  Interaction.prototype.setMap.call(this, map);
};

/**
 * Activate or deactivate the interaction.
 * @param {boolean} active Active.
 * @observable
 */
PointQuery.prototype.setActive = function (active) {
  if (!active) {
    this.cleanUpMap_();
  }
  Interaction.prototype.setActive.call(this, active);
};

/**
 * Clean up current map instance
 * @private
 */
PointQuery.prototype.cleanUpMap_ = function () {
  var map_ = this.getMap();
  if (map_) {
    if (this.overlay_) {
      this.clearMarker(map_, this.overlay_);
    }
  }
};

/**
 * Dispatches Event with query results and/or errors
 * @param {ol/Map~Map} map Map instance against which the query has been performed
 * @param {Array<Error|null>} errors Array containing either null (no error) or Error instance for each infoLayer (in the same order as `infoLayers`)
 * @param {Array<ol/layer/Layer~Layer>} infoLayers Layers that have been queried
 * @param {Array<FeatureCollection>} featureCollections OpenLayers FeatureCollections
 * @param {Array<Float>} coordinates Location queried
 */
PointQuery.prototype.resultHandler = function (
  map,
  errors,
  infoLayers,
  featureCollections,
  coordinates
) {
  var hasErrors = !!errors.filter(Boolean).length;
  this.dispatchEvent({
    type: 'results',
    errors: errors,
    hasErrors: hasErrors,
    infoLayers: infoLayers,
    featureCollections: featureCollections,
    coordinate: coordinates,
    map: map
  });
};

/**
 * Requests info for all layers marked as displaying info and calls handleResponse when complete
 * @param {ol/Map~Map} map Map instance to query
 * @param {Array<Float>} coordinates Location to query
 */
PointQuery.prototype.requestInfo = function (map, coordinates) {
  // Each time we're called increment the instance requestCounter and
  // record the request number of this request (allows us to skip out-of-date
  // requests when they return)
  var requestNum = ++this.requestCounter;
  var infoLayers = getInfoLayers(map.getLayerGroup());

  if (this.queryLayers_) {
    var ql = this.queryLayers_;
    infoLayers = infoLayers.reduce(function (selectedLayers, layer) {
      var config = layer.get('iShare:config');
      if (ql.indexOf(config.layerName) > -1) {
        selectedLayers.push(layer);
      }
      return selectedLayers;
    }, []);
  }
  this.dispatchEvent({
    type: 'query',
    infoLayers: infoLayers,
    coordinates: coordinates,
    map: map
  });
  getInfoAtPoint(map, infoLayers, coordinates).then(
    function (results) {
      if (requestNum === this.requestCounter) {
        this.resultHandler(
          map,
          results.errors,
          results.infoLayers,
          results.collections,
          coordinates
        );
      } else {
        //Skipping handling request as a more recent request supersedes it
      }
    }.bind(this)
  );
};

/**
 * Add marker at provided coordinates
 * @private
 * @param {Array<Float>} coordinates Location at which marker is to be placed
 */
PointQuery.prototype.addMarker_ = function (coordinates) {
  var map = this.getMap();
  var markerLayer = this.overlay_;
  var clearMarker_ = this.clearMarker.bind(this);
  var options = this.markerOptions_;
  var point = new Feature(new Point(coordinates));
  if (options.style) {
    point.setStyle(options.style);
  }

  clearMarker_();
  markerLayer.setMap(map);
  ping(map, markerLayer, point.getGeometry(), 1000, [255, 0, 0]).then(function (
    evt
  ) {
    if (!options.persist) {
      clearMarker_();
    }
  });
  markerLayer.getSource().addFeature(point);
};

/**
 * Clean up current displayed marker
 */
PointQuery.prototype.clearMarker = function () {
  if (this.overlay_) {
    this.overlay_.getSource().clear(false);
    this.overlay_.setMap(null);
  }
};

export { PointQuery, ping };
