/**
 * Create OpenLayers Map, View, Layer etc. instances based on an iShare profile.
 *
 * ESM example:
 * ```
 * import * as imap from 'ol-ishare/map'
 *
 * // All functions in the info module are accessible as properties of an `imap` Object
 * let lyr = imap.createVectorLayer(profile, layerName);
 * ```
 *
 * Global example:
 * ```
 * // All functions in the map module are accessible as properties of `oli.map`
 * var lyr = oli.map.createVectorLayer(profile, layerName);
 * ```
 *
 * @module ol-ishare/map
 */
import * as proj from 'ol/proj';
import FormatGML2 from 'ol/format/GML2';
import ImageLayer from 'ol/layer/Image';
import LayerGroup from 'ol/layer/Group';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import SourceImageWMS from 'ol/source/ImageWMS';
import SourceTileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import TilegridTileGrid from 'ol/tilegrid/TileGrid';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import { bbox } from 'ol/loadingstrategy';
import { getBaseConfig, getGroupConfig, getLayerConfig } from './profile.js';
import { getWfsGetFeatureUrl } from './info.js';
import { nearestZoom } from './view.js';
import { register } from 'ol/proj/proj4';

/**
 * Creates an `ol/layer/Group~LayerGroup` instance for the iShare layer group found in `profile` with the specified GUID
 * ```
 * import { createGroup } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @param {String} guid GUID of the layer group to create
 * @returns {ol/layer/Group~LayerGroup} Group instance
 */
function createGroup(profile, guid) {
  var groupConfig = getGroupConfig(profile.layerGroups, guid);
  var group = new LayerGroup({
    'iShare:guid': groupConfig.guid,
    'iShare:config': {
      displayName: groupConfig.displayName
    },
    'title': groupConfig.displayName
  });
  return group;
}

/**
 * Create a WMS image layer
 * ```
 * import { createImageLayer } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @param {String} layerName The name of the layer to create
 * @returns {ol/layer/Image~Image} Newly created WMS layer
 */
function createImageLayer(profile, layerName) {
  var layerConfig = getLayerConfig(profile.layerGroups, layerName);
  var sourceOpts = {
    crossOrigin: 'Anonymous',
    url: profile.config.owsUrl,
    params: {
      LAYERS: layerName,
      VERSION: '1.1.1'
    },
    projection: profile.projection.SRS + ':' + profile.projection.SRID,
    extent: profile.extent
  };
  if (layerConfig.thematic && layerConfig.thematic.sld) {
    sourceOpts.params.sld = layerConfig.thematic.sld;
  }
  var source = new SourceImageWMS(sourceOpts);
  var layer_opts = {
    'source': source,
    'iShare:layerName': layerName,
    'iShare:config': layerConfig,
    'visible': layerConfig.initiallyVisible,
    'zIndex': layerConfig.zIndex,
    'opacity': Math.min(Math.max(layerConfig.opacity, 0), 1)
  };
  if (!layerConfig.hidden) {
    layer_opts.title = layerConfig.displayName;
  }
  var layer = new ImageLayer(layer_opts);
  return layer;
}

/**
 * Create a WFS vector layer
 * ```
 * import { createVectorLayer } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @param {String} layerName The name of the layer to create
 * @returns {ol/layer/Vector~Vector} Newly created WFS layer
 */
function createVectorLayer(profile, layerName) {
  var layerConfig = getLayerConfig(profile.layerGroups, layerName);
  var source = new VectorSource({
    format: new FormatGML2(),
    url: function (extent, resolution, proj) {
      return (
        getWfsGetFeatureUrl(profile.config.owsUrl, layerName, 'GML2') +
        '&bbox=' +
        extent.join(',') +
        ',' +
        proj.getCode()
      );
    },
    strategy: bbox
  });
  var layer_opts = {
    'source': source,
    'iShare:layerName': layerName,
    'iShare:config': layerConfig,
    'visible': layerConfig.initiallyVisible
  };
  if (!layerConfig.hidden) {
    layer_opts.title = layerConfig.displayName;
  }
  var layer = new VectorLayer(layer_opts);
  return layer;
}

/**
 * Create a tiled WMS base layer
 * ```
 * import { createBaseLayer } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @param {String} layerName The name of the base layer to create
 * @returns {ol/layer/Tile} Base map layer
 */
function createBaseLayer(profile, layerName) {
  var baseMap = getBaseConfig(profile.baseMaps, layerName);
  return new TileLayer({
    'title': baseMap.displayName,
    'type': 'base',
    'visible': profile.defaultBaseMap === baseMap.mapName,
    'iShare:layerName': layerName,
    'iShare:config': baseMap,
    'source': new SourceTileWMS({
      crossOrigin: 'Anonymous',
      url: baseMap.url,
      attributions: [profile.attribution],
      attributionsCollapsible: false,
      params: {
        LAYERS: baseMap.layers.join(','),
        FORMAT: baseMap.format,
        VERSION: '1.1.1'
      },
      tileGrid: new TilegridTileGrid({
        origin: profile.extent.slice(0, 2),
        resolutions: profile.resolutions
      })
    }),
    'opacity': 1
  });
}

/**
 * Creates a group of tiled WMS base layers
 * ```
 * import { createBaseGroup } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @returns {ol/layer/Group~LayerGroup} Base map layer group
 */
function createBaseGroup(profile) {
  var baseMaps = profile.baseMaps.map(function (baseMapDef) {
    return createBaseLayer(profile, baseMapDef.mapName);
  });
  return new LayerGroup({
    'iShare:guid': 'basemaps',
    'iShare:config': {},
    'title': 'Base maps',
    'layers': baseMaps
  });
}

/**
 * Create an Array of `ol/layer/Group~LayerGroup` for a profile each containing one or more layers
 * ```
 * import { createGroupsAndOverlays } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile returned by `module:ol-ishare/profile#getProfile`
 * @param {Boolean} [onlyVisible=true] If true only create `initiallyVisible` layers and their groups
 * @param {Boolean} [useProfileLayerOrder=false] If true add layers in reverse order
 *  - means that the first listed will be at the top (for a given z-index)
 * @returns {Array<ol/layer/Group~LayerGroup>} A list of groups which contain the newly created layers
 */
function createGroupsAndOverlays(profile, onlyVisible, useProfileLayerOrder) {
  // Loop through each group in profile if onlyVisible and the group contains a
  // visible layer, create it then create the visible layer(s) and add those to the group
  var groups = [];
  var groupsByGuid = {};
  for (
    var m = 0, groupConfig, groupLayer;
    m < profile.layerGroups.length;
    m++
  ) {
    groupConfig = profile.layerGroups[m];
    for (var n = 0, layerConfig; n < groupConfig.layers.length; n++) {
      layerConfig = groupConfig.layers[n];
      if (
        layerConfig.hidden === false &&
        (onlyVisible === false || layerConfig.initiallyVisible)
      ) {
        // Find the existing group or create and add it to the map
        groupLayer = groupsByGuid[groupConfig.guid];
        if (!groupLayer) {
          groupLayer = createGroup(profile, groupConfig.guid);
          groupsByGuid[groupConfig.guid] = groupLayer;
          if (useProfileLayerOrder === true) {
            groups.unshift(groupLayer);
          } else {
            groups.push(groupLayer);
          }
        }
        // Create the layer and add it to the group
        var layer = createImageLayer(profile, layerConfig.layerName);
        if (useProfileLayerOrder === true) {
          groupLayer.getLayers().insertAt(0, layer);
        } else {
          groupLayer.getLayers().push(layer);
        }
      }
    }
  }
  return groups;
}

/**
 * Creates an `ol/View~View` instance for a given profile and mapSize.
 * Configures the projection, resolutions, initial centre and zoom.
 * The profile.view properties such as easting, northing and zoom can
 * be overridden to affect the initial view. If a profile.view.resolution
 * is defined it will take precidence over the zoom property.
 * ```
 * import { createView } from 'ol-ishare/map';
 * ```
 * @param {module:ol-ishare/profile~Profile} profile iShare profile
 * @param {ol/size~Size} mapSize Width and height of the map as returned by `ol/PluggableMap~PluggableMap#getSize`
 * @param {Boolean} [useInitial=false] If `true` then starts with the current view set in the profile, otherwise uses `.initialView`
 * @returns {ol/View~View} OpenLayers View instance
 */
function createView(profile, mapSize, useInitial) {
  useInitial = useInitial === true; //must explicitly be set to true to change behaviour
  var view = useInitial ? profile.initialView : profile.view;
  var p = profile.projection;
  var code = p.SRS + ':' + p.SRID;

  // Attempt to get projection shipped with OpenLayers
  var projection = proj.get(code);

  // Attempt to access global proj4 instance
  if (window.proj4) {
    console.info('Found proj4, creating official projection...');
    window.proj4.defs(code, p.definition);
    register(window.proj4);
    projection = proj.get(code);
  } else if (projection === null) {
    console.info(
      'Could not find proj4, or built in projection, creating basic projection'
    );
    projection = new Projection({
      code: code,
      units: (p.definition.match(/\+units=(\w+)/) || ['', 'm'])[1]
    });
    proj.addProjection(projection);
  }

  var viewOpts = {
    projection: projection,
    resolutions: profile.resolutions,
    center: [view.easting, view.northing],
    constrainResolution: true,
    extent: profile.extent,
    constrainOnlyCenter: true,
    smoothExtentConstraint: true,
    showFullExtent: true
  };

  // If a resolution is passed it overrides the zoom as it's
  // more specific and doesn't alter the scale depending on map width
  if (view.resolution) {
    viewOpts.resolution = view.resolution;
  } else {
    // At a minimum profile.view.zoom should be defined
    viewOpts.zoom = nearestZoom(view.zoom, mapSize[0], profile.resolutions);
  }

  return new View(viewOpts);
}

/**
 * Create an OpenLayers Map instance
 * ```
 * import { createMap } from 'ol-ishare/map';
 * ```
 * @param {String} target DOM element that the map will be created within
 * @param {ol/PluggableMap~MapOptions} ol_opts OpenLayers Map Options
 * @returns {ol/Map~Map} map Map instance
 */
function createMap(target, ol_opts) {
  ol_opts = ol_opts || {};
  ol_opts.target = target;

  var map = new Map(ol_opts);

  return map;
}

export {
  createMap,
  createView,
  createGroup,
  createImageLayer,
  createVectorLayer,
  createBaseLayer,
  createBaseGroup,
  createGroupsAndOverlays
};
