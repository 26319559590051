/**
 * @module ol-ishare/search
 */

import { appendParams } from './http.js';
import { getFeature } from './info.js';
import { getOwsUrl } from './profile.js';
import { like } from 'ol/format/filter';
import { writeFilter } from 'ol/format/WFS';

/**
 * Address search instance compatible with Gazetteer control, uses iShare LocationSearch web service.
 * To use with {@link module:ol-ishare/control/gazetteer~Gazetteer} pass an instance of AddressSearch as a member of {@link module:ol-ishare/control/gazetteer~Gazetteer} `options.sources` Array.
 * ```
 * import { AddressSearch } from 'ol-ishare/search';
 * ```
 * @class
 * @classdesc Address search instance compatible with Gazetteer control
 * @implements {module:ol-ishare/ui/gazetteer~GazetteerSource}
 * @param {Object} options Options
 * @param {String} options.iShareUrl Base URL for iShare Web app
 * @param {String} options.profile Name of profile that the search is associated with
 * @param {String} [options.srs='EPSG:27700'] EPSG code of the profile defaults to EPSG:27700 (British National Grid)
 * @param {Number} [options.pageSize=10] Number of results to fetch per request, maximum of 100
 */
var AddressSearch = (function () {
  function AddressSearch(options) {
    var pageSize = options.pagesize || 10;
    this.url = options.iShareUrl.replace(/https?:/, '') + 'getdata.aspx';
    this.profile = options.profile;
    this.srs = options.srs || 'EPSG:27700';
    this.pageSize = Math.max(pageSize, 100);
  }

  AddressSearch.prototype.constructor = AddressSearch;

  AddressSearch.prototype.getParams = function (query) {
    return {
      mapsource: this.profile,
      location: query,
      service: 'LocationSearch',
      RequestType: 'LocationSearch',
      pagesize: this.pageSize,
      startnum: 1,
      gettotals: false
    };
  };

  /**
   * Fetches address search results, returns a Promise which will resolve to
   * an Array of {@link module:ol-ishare/ui/gazetteer~GazetteerResult} objects or an error
   * @param {string} query String to search for
   * @returns {Promise<Array<module:ol-ishare/ui/gazetteer~GazetteerResult>>} Promise that resolves to an Array of GazetteerResult objects or an Error
   */
  AddressSearch.prototype.search = function (query) {
    var params = this.getParams(query);
    var url = appendParams(this.url, params);
    var self = this;
    return fetch(url)
      .then(function (resp) {
        return resp.json();
      })
      .then(function (json) {
        var results = self.parseResults(json);
        // console.log(results);
        return results;
      })
      .catch(function (err) {
        // console.debug('Error while searching for address:', query, '\n\n', err);
        return [];
      });
  };

  /**
   * Parse results to an Array<{@link module:ol-ishare/ui/gazetteer~GazetteerResult}>
   * @param {Object} results Results object as returned by LocationSearch web service
   * @returns {Array<module:ol-ishare/ui/gazetteer~GazetteerResult>} Array of GazetteerResult objects
   * @private
   */
  AddressSearch.prototype.parseResults = function (results) {
    if (results && results.data) {
      var that = this;
      return results.data.map(function (result) {
        var coord = [parseFloat(result[4], 10), parseFloat(result[5], 10)];
        return {
          id: result[0],
          text: result[7],
          formatted: result[2],
          coord: coord,
          srs: that.srs
        };
      });
    } else {
      return [];
    }
  };

  return AddressSearch;
})();

/**
 * Layer search instance compatible with {@link module:ol-ishare/control/gazetteer~Gazetteer} control
 * To use with {@link module:ol-ishare/control/gazetteer~Gazetteer} pass an instance of LayerSearch as a member of {@link module:ol-ishare/control/gazetteer~Gazetteer} `options.sources` Array.
 * ```
 * import { LayerSearch } from 'ol-ishare/search';
 * ```
 * @class
 * @classdesc Layer search instance compatible with Gazetteer control. See {@link module:ol-ishare/search~layerSearchForLayer} to create a LayerSearch from an existing overlay layer.
 * @implements {module:ol-ishare/ui/gazetteer~GazetteerSource}
 * @param {Object} options Options
 * @param {String} options.iShareUrl Base URL for iShare Web app
 * @param {String} options.profile Name of profile that the search is associated with
 * @param {String} options.owsUrl Base URL for WMS/ WFS requests (only required when `iShareUrl` and `profile` options are not supplied)
 * @param {String} options.layerName Name of layer to search
 * @param {String} options.searchField Name of field to search
 * @param {String} options.srs EPSG code of the search results defaults to EPSG:27700 (British National Grid)
 * @param {Function} options.formatResult Function called with `feature` argument, expected to return a string that will be displayed to the user
 * @param {Number} options.maxFeatures Maximum number of features to return from the server
 */
var LayerSearch = (function () {
  function LayerSearch(options) {
    this.iShareUrl = options.iShareUrl;
    this.profile = options.profile;
    this.owsUrl = options.owsUrl;
    this.layerName = options.layerName;
    this.searchField = options.searchField;
    this.srs = options.srs || 'EPSG:27700';
    // Optional - Function used to generate the formatted text to display to the user
    this.formatResult = options.formatResult;
    // Optional max features to fetch
    this.maxFeatures = options.maxFeatures;
  }

  LayerSearch.prototype.constructor = LayerSearch;

  /**
   * Convert an {@link ol/Feature~Feature} to a {@link module:ol-ishare/ui/gazetteer~GazetteerResult} object
   * @param {ol/Feature~Feature} feature The feature
   * @returns {module:ol-ishare/ui/gazetteer~GazetteerResult} A GazetteerResult object
   * @private
   */
  LayerSearch.prototype.featureToResult = function (feature) {
    var searchField = this.searchField;
    var result = {
      id: feature.getId(),
      text: feature.get(searchField),
      formatted: this.formatResult
        ? this.formatResult(feature)
        : feature.get(searchField),
      srs: this.srs
    };
    var geom = feature.getGeometry();
    // console.log(geom.getType());
    if (geom.getType() === 'Point') {
      // console.log(geom.getCoordinates());
      result.coord = geom.getCoordinates();
    } else {
      // console.log(geom.getExtent());
      result.bbox = geom.getExtent();
    }
    return result;
  };

  /**
   * Fetches feature search results, returns a Promise which will resolve to
   * an Array of {@link module:ol-ishare/ui/gazetteer~GazetteerResult} objects or an error
   * @param {string} query String to search for
   * @returns {Promise<Array<module:ol-ishare/ui/gazetteer~GazetteerResult>>} Promise that resolves to an Array of GazetteerResult objects or an Error
   */
  LayerSearch.prototype.search = function (query) {
    var owsUrl = this.owsUrl || getOwsUrl(this.iShareUrl, this.profile);
    var filter = writeFilter(
      like(this.searchField, '*' + query + '*', '*', '.', '!', false),
      '1.0.0'
    );
    var maxFeatures = this.maxFeatures || 30;
    var that = this;
    return getFeature(owsUrl, that.layerName, filter, {
      maxfeatures: maxFeatures
    }).then(function (features) {
      var results = features.map(function (feature) {
        return that.featureToResult(feature);
      });
      return results;
    });
  };

  return LayerSearch;
})();

/**
 * Creates a LayerSearch instance for a given ol-ishare overlay layers ({@link ol/layer/Image~ImageLayer}).
 * Reads iShare `layerName` and `searchField` (if present) from the layer's Profile config.
 * ```
 * import { layerSearchForLayer } from 'ol-ishare/search';
 * ```
 * @param {ol/layer/Image~ImageLayer} layer overlay layer to create a LayerSearch instance for
 * @param {Object} options Options passed to LayerSearch constructor. Commonly only the `srs` option is set (as it can't be determined from the layer). Options override layer properties.
 * @param {String} options.layerName Name of layer to search
 * @param {String} options.searchField Name of field to search (required if not set in the Profile)
 * @param {String} options.srs EPSG code of the search results defaults to EPSG:27700 (British National Grid)
 * @param {Function} options.formatResult Function called with `feature` argument (of type {@link ol/Feature~Feature}), expected to return a string that will be displayed to the user
 * @param {Number} options.maxFeatures Maximum number of features to return from the server
 * @returns {module:ol-ishare/search~LayerSearch} LayerSearch instance
 */
function layerSearchForLayer(layer, options) {
  var opts = Object.assign(
    {
      owsUrl: layer.getSource().getUrl(),
      layerName: layer.get('iShare:config').layerName,
      searchField: layer.get('iShare:config').searchField
    },
    options
  );
  return new LayerSearch(opts);
}

export { AddressSearch, LayerSearch, layerSearchForLayer };
