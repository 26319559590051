/**
 * Functions for working with WMS and WFS
 *
 * ESM example:
 * ```
 * import * as info from 'ol-ishare/info'
 *
 * // All functions in the info module are accessible as properties of an `info` Object
 * let url = info.getWfsUrl(owsUrl);
 * ```
 *
 * Global example:
 * ```
 * // All functions in the info module are accessible as properties of `oli.info`
 * var url = oli.info.getWfsUrl(owsUrl);
 * ```
 *
 * @module ol-ishare/info
 */

/**
 * @typedef InfoResult object containing results from (potentially) multiple layers
 * @property {Array<Error|null>} errors Array containing either null (no error) or Error instance for each infoLayer (in the same order as `infoLayers`)
 * @property {Array<ol/layer/Layer~Layer>} infoLayers Info layers passed to `getInfoAtPoint`
 * @property {Array<FeatureCollection>} collections Result GeoJSON FeatureCollections
 */

import * as info from './info.js';
import Filter from 'ol/format/filter/Filter';
import FormatGML2 from 'ol/format/GML2';
import FormatWMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import SourceImageWMS from 'ol/source/ImageWMS';
import SourceTileWMS from 'ol/source/TileWMS';
import SourceVector from 'ol/source/Vector';
import { appendParams, checkHttpStatusIsOk } from './http.js';
import { equalTo } from 'ol/format/filter';
import { and as filterAnd, bbox as filterBBOX } from 'ol/format/filter';
import { findLayers } from './olutil.js';
import { writeFilter } from 'ol/format/WFS';

const DEFAULT_WFS_VERSION = '1.0.0';
const DEFAULT_FILTER_VERSION = '1.0.0';

/**
 * Create a HTML String by applying a default info template for each layer from `infoLayers` and the corresponding features from `featureCollections`.
 * ```
 * import { infoResultsToHtml } from 'ol-ishare/info';
 * ```
 * @param {Array<Error|null>} errs Array containing either null (no error) or Error instance for each infoLayer (in the same order as `infoLayers`)
 * @param {Array<ol/layer/Base~BaseLayer>} infoLayers Layers for which features have been queried
 * @param {Array<Array<ol/Feature~Feature>>} featureCollections An Array containing an Array of features for each `infoLayer`
 * @returns {String} HTML suitable for display
 */
function infoResultsToHtml(errs, infoLayers, featureCollections) {
  var html = featureCollections
    .map(function (features, index) {
      // Get a reference to the layer based on the index of the
      // response in the results Array
      var infoLayer = infoLayers[index];
      var err = errs[index];
      return infoResultToHtml(err, infoLayer, features);
    })
    .join('\n');
  return html;
}

/**
 * Create a HTML String by applying a default info template for `infoLayer` and the corresponding features from `features`.
 * ```
 * import { infoResultToHtml } from 'ol-ishare/info';
 * ```
 * @param {Error|null} err Either null (no error) or Error instance for the infoLayer
 * @param {ol/layer/Base~BaseLayer} infoLayer Layer for which features have been queried
 * @param {Array<ol/Feature~Feature>} features An Array of features for `infoLayer`
 * @returns {String} HTML suitable for display
 */
function infoResultToHtml(err, infoLayer, features) {
  var layerConfig = infoLayer.get('iShare:config');
  var inFrame = window.self !== window.top;
  if (layerConfig.infoTemplate) {
    if (window.nunjucks) {
      // All good we've got nunjucks available to render the templates
    } else {
      console.info(
        'Found custom info templates but nunjucks was not found. To enable custom info templates load nunjucks via a script tag.'
      );
    }
  }
  var html = '';
  if (layerConfig.infoClick) {
    if (layerConfig.infoTemplate && window.nunjucks) {
      html = window.nunjucks.renderString(layerConfig.infoTemplate, {
        layerConfig: layerConfig,
        features: features,
        error: err
      });
    } else {
      var featuresHtml = '';
      featuresHtml = features
        .map(function (feature) {
          var html = '<div class="feature">';
          html += layerConfig.fields
            .map(function (field, idx) {
              var val = feature.get(field.name);
              // If the column value is null or undefined skip it
              if (val === null || val === undefined) {
                return '';
              }
              var url = null;
              if (field.link) {
                if (field.link.baseUrl) {
                  url = field.link.baseUrl.replace(
                    '{0}',
                    feature.get(field.link.value)
                  );
                } else {
                  url = feature.get(field.link.value);
                }
              }
              var column = '';
              if (field.displayName) {
                column =
                  '<strong class="column">' + field.displayName + '</strong> ';
              }
              return (
                '<p>' +
                column +
                '<span class="value' +
                (idx === 0 ? ' first' : '') +
                '">' +
                (url
                  ? '<a href="' +
                    url +
                    '"' +
                    (inFrame ? ' target="_new"' : '') +
                    '>'
                  : '') +
                feature.get(field.name) +
                (url ? '</a>' : '') +
                '</span>' +
                '</p>'
              );
            })
            .join('\n');
          html += '</div>';
          return html;
        })
        .join('\n');
      if (err) {
        featuresHtml += '<div class="error">' + err.message + '</div>';
      }
      if (featuresHtml.length) {
        html += '<div class="layer"><h3>' + layerConfig.displayName + '</h3>';
        html += featuresHtml;
        html += '</div>';
      }
    }
  }
  return html;
}

/**
 * Parse WMS GetFeatureInfo response into Array of features, suitable for use in a Promise chain
 * @function
 * @param {String} text String to parse
 * @returns {Array.<ol/Feature~Feature>} Array of features parsed from `text`
 * @throws `Error` if features can't be parsed. Error instance has `info` property
 *   with value of the `text` that could not be parsed.
 */
var wmsGetFeatureInfoReader = createFeatureReader(
  FormatWMSGetFeatureInfo,
  ':gml'
);

/**
 * Parse WFS GetFeature response into Array of features, suitable for use in a Promise chain
 * @function
 * @param {String} text String to parse
 * @returns {Array.<ol/Feature~Feature>} Array of features parsed from `text`
 * @throws `Error` if features can't be parsed. Error instance has `info` property
 *   with value of the `text` that could not be parsed.
 */
var wfsGetFeatureReader = createFeatureReader(FormatGML2, 'FeatureCollection');

/**
 * Gets the info tolerance settings suitable for use as a query string parameter value.
 * If infoTolerance is passed (generally accessed from layerConfig.infoTolerance) then
 * its values will be used, otherwise the defaultTolerance values will be used.
 * If the infoTolerance Object has no properties then the returned value will be undefined
 * and the mapfile values are used.
 * @param {Object} defaultTolerance Object with default tolerance and toleranceUnit properties
 * @param {Object} infoTolerance Object with tolerance and toleranceUnit properties
 * @returns {String} URL parameter value to be associated with the layer
 */
function getInfoTolerance(defaultTolerance, infoTolerance) {
  infoTolerance = infoTolerance || defaultTolerance;
  // Derive the infoTolerance from the layerConfig. If the infoTolerance Object has
  // no properties then the infoTolerance isn't set and the mapfile values are used
  infoTolerance = Object.keys(infoTolerance)
    .map(function (key) {
      return key.toUpperCase() + '+' + infoTolerance[key];
    })
    .join('+');
  return infoTolerance;
}

/**
 * Queries for features at `coordinate` from each `ol/layer/Layer~Layer`.
 *
 * ```
 * import { getInfoAtPoint } from 'ol-ishare/info';
 * ```
 *
 * The info tolerance can be specified for each layer by setting a `infoTolerance` property of the layer config to an Object with `tolerance` and `toleranceUnit` values. For example:
 *
 * ```
 * // Only features that are exactly at the point clicked will be returned
 * layerConfig.infoTolerance = {
 *   tolerance: 0,
 *   toleranceUnits: 'PIXELS'
 * }
 * ```
 *
 * @param {ol/Map} map OpenLayers Map instance
 * @param {Array<ol/layer/Layer~Layer>} infoLayers Layers to be queried
 * @param {Array<Number>} coordinate Coordinate that will be queried
 * @returns {Promise} resolves to Promise with {module:ol-ishare/info~InfoResult} argument
 */
function getInfoAtPoint(map, infoLayers, coordinate) {
  function infoRequest(map, layer, coordinate) {
    // TODO Make this more modular and allow users to add support for other sources?
    if (
      layer.getSource() instanceof SourceImageWMS ||
      layer.getSource() instanceof SourceTileWMS
    ) {
      var layerName = layer.get('iShare:layerName');
      var layerConfig = layer.get('iShare:config');

      var wmsInfoOpts = {
        INFO_FORMAT: 'application/vnd.ogc.gml',
        FEATURE_COUNT: 10
      };

      var layerTolerance = layer.get('iShare:config').infoTolerance;
      if (layerTolerance || layerConfig.geometry.type !== 'POLYGON') {
        // TODO We should pickup default tolerance settings from the atMapSettingsJS.aspx
        // (pixelTolerance) while still allowing settings to be overriden via layerConfig
        var infoTolerance = getInfoTolerance(
          { toleranceUnits: 'PIXELS', tolerance: 10 },
          layerTolerance
        );
        if (infoTolerance) {
          wmsInfoOpts['map.layer[' + layerName + ']'] = infoTolerance;
        }
      }

      var wmsInfoUrl = layer
        .getSource()
        .getFeatureInfoUrl(
          coordinate,
          map.getView().getResolution(),
          map.getView().getProjection(),
          wmsInfoOpts
        );

      return fetch(wmsInfoUrl)
        .then(checkHttpStatusIsOk)
        .then(function (response) {
          return response.text();
        })
        .then(wmsGetFeatureInfoReader)
        .then(function (features) {
          return { error: null, features: features };
        })
        .catch(function (err) {
          return { error: err, features: [] };
        });
    } else if (layer.getSource() instanceof SourceVector) {
      var px = map.getPixelFromCoordinate(coordinate);
      var features = [];
      map.forEachFeatureAtPixel(
        px,
        function (feature) {
          features.push(feature);
        },
        {
          layerFilter: function (candidateLayer) {
            return candidateLayer === layer;
          }
        }
      );
      if (features.length) {
        var firstFeature = features[0];
        if (
          firstFeature.get('features') &&
          firstFeature.get('features').length
        ) {
          // We're dealing with a cluster layer so we need to get the features
          // from the cluster features
          features = features.reduce(function (features, clusterFeature) {
            return features.concat(clusterFeature.get('features'));
          }, []);
        }
        return Promise.resolve({ features: features, error: null });
      } else {
        return Promise.resolve({ features: [], error: null });
      }
    }
  }

  var requests = infoLayers.map(function (layer) {
    return infoRequest(map, layer, coordinate);
  });
  return Promise.all(requests).then(function (results) {
    // results is an Array of {features:Array<Feature>, error:Error|null}
    return {
      collections: results.map(function (result) {
        return result.features;
      }),
      errors: results.map(function (result) {
        return result.error;
      }),
      infoLayers: results.length ? infoLayers : []
    };
  });
}

/**
 * Get a list of all `ol/layer/Layer~Layer` instances that should be queried for info based on the layer's config in the profile
 * ```
 * import { getInfoLayers } from 'ol-ishare/info';
 * ```
 * @param {ol/layer/Group~LayerGroup} layerGroup Generally the root `ol/Map~Map` layer group obtained via `map.getLayerGroup()`
 * @returns {Array<ol/layer/Layer~Layer>} List of layers for which info should be fetched
 */
function getInfoLayers(layerGroup) {
  return findLayers(layerGroup, function (layer) {
    var layerConfig = layer.get('iShare:config');
    return (
      layer.getVisible() &&
      layerConfig &&
      layerConfig.infoClick &&
      (layer.getSource() instanceof SourceImageWMS ||
        layer.getSource() instanceof SourceTileWMS ||
        layer.getSource() instanceof SourceVector)
    );
  }).reverse();
}

/**
 * Get a WFS URL based on a base OWS URL
 * ```
 * import { getWfsUrl } from 'ol-ishare/info';
 * ```
 * @param {String} owsUrl Base URL for WMS/ WFS services
 * @returns {String} Base WFS URL
 */
function getWfsUrl(owsUrl) {
  var params = {
    VERSION: DEFAULT_WFS_VERSION,
    SERVICE: 'WFS'
  };
  var url = appendParams(owsUrl, params);
  return url;
}

/**
 * Get a complete WFS GetFeature URL based on a base OWS URL, layerName
 * and optional output format and OGC Filter.
 * ```
 * import { getWfsGetFeatureUrl } from 'ol-ishare/info';
 * ```
 * @param {String} owsUrl Base URL for WMS/ WFS services
 * @param {String} layerName The name of the layer to query
 * @param {String} outputFormat Optional WFS output format, defaults to GML2
 * @param {Element|String} filter Optional OpenLayers OGC filter as an XML Element or String
 * @param {Object} params Optional Object with key/ value pair of params to include in the query string,
 *   commonly used for vender parameters such as iShare VIEWPARAMS.
 * @returns {String} Complete WFS GetFeature URL
 */
function getWfsGetFeatureUrl(owsUrl, layerName, outputFormat, filter, params) {
  var wfsUrl = getWfsUrl(owsUrl);
  var params_ = {
    REQUEST: 'GetFeature',
    TYPENAME: layerName,
    OUTPUTFORMAT: outputFormat || 'GML2'
  };
  if (params) {
    for (var key in params) {
      params_[key] = params[key];
    }
  }
  // TODO Can we automatically add VIEWPARAMS if not passed via params?
  // Ideally add a BBOX VIEWPARAMS; we might not be able to in this sort of
  // situation: `intersects geometry OR property equalTo 'baz'` as a BBOX
  // VIEWPARAMS for the geometry would exclude features with a property
  // equal to 'baz' that falls outside the BBOX.
  if (filter) {
    // If filter is an XML doc convert it to a String
    if (filter.nodeName) {
      params_['FILTER'] = new XMLSerializer().serializeToString(filter);
    } else {
      params_['FILTER'] = filter;
    }
  }
  var url = appendParams(wfsUrl, params_);
  return url;
}

/**
 * Make a WFS GetFeature request for features from a given OWS server, layer name, optionally applying a filter.
 * ```
 * import { getFeatureText } from 'ol-ishare/info';
 * ```
 * @param {String} owsUrl Base URL for WMS/ WFS services
 * @param {String} layerName The name of the layer to query
 * @param {Element|String} filter Optional OpenLayers OGC filter as an XML Element or String
 * @param {Object} params Optional Object with key/ value pair of params to include in the query string,
 *   commonly used for vender parameters such as iShare VIEWPARAMS.
 * @param {String} [outputFormat='GML2'] OGC standard, or vendor-specific, format in which to return the data
 * @returns {Promise} resolves to response
 */
function getFeatureResponse(owsUrl, layerName, filter, params, outputFormat) {
  var url = info.getWfsGetFeatureUrl(
    owsUrl,
    layerName,
    outputFormat || 'GML2',
    filter,
    params
  );
  return fetch(url).then(checkHttpStatusIsOk);
}

/**
 * Make a WFS GetFeature request for features from a given OWS server, layer name, optionally applying a filter.
 * ```
 * import { getFeature } from 'ol-ishare/info';
 * ```
 * @param {String} owsUrl Base URL for WMS/ WFS services
 * @param {String} layerName The name of the layer to query
 * @param {Element|String} filter Optional OpenLayers OGC filter as an XML Element or String
 * @param {Object} params Optional Object with key/ value pair of params to include in the query string,
 *   commonly used for vender parameters such as iShare VIEWPARAMS.
 * @returns {Promise} resolves to OpenLayers {@link ol/Feature~Feature} objects for data in response
 */
function getFeature(owsUrl, layerName, filter, params) {
  return getFeatureResponse(owsUrl, layerName, filter, params)
    .then(function (response) {
      return response.text();
    })
    .then(wfsGetFeatureReader);
}

/**
 * Make a WFS GetFeature request for features which match the equalTo filter.
 * ```
 * import { getFeatureByValue } from 'ol-ishare/info';
 * ```
 * @param {String} owsUrl Base URL for WMS/ WFS services
 * @param {String} layerName The name of the layer to query
 * @param {String} column Name of the column to query
 * @param {String} value Value to query column for
 * @returns {Promise} `resolve` gets  {Array<ol/Feature~Feature>} features and `reject` gets {Error} error
 */
function getFeatureByValue(owsUrl, layerName, column, value) {
  var filter = writeFilter(equalTo(column, value), '1.0.0');
  return getFeature(owsUrl, layerName, filter, null);
}

/**
 * Creates a function to read a string of features in a given format
 * @package
 * @param {Function} formatClass Class constructor used to create a reader
 * @param {String} testStr String that must be in the text that is parsed
 *   for the text to be considered a valid feature collection (commonly 'FeatureCollection' or ':gml')
 * @returns {Function} Function that accepts a single `text` argument to read features from,
 *   throws an exception if features can't be parsed. Suitable for use in a Promise chain
 */
function createFeatureReader(formatClass, testStr) {
  return function readFeatures(text) {
    var reader = new formatClass();
    var features = reader.readFeatures(text);
    // Either we've got features or an empty FeatureCollection
    if (
      features.length ||
      text.indexOf(testStr) > 0 ||
      text.indexOf('Search returned no results.') > 0
    ) {
      return features;
    } else {
      var err = new Error('Unable to parse features');
      err.info = text;
      throw err;
    }
  };
}

/**
 * Requests features (that match a WFS filter) for the specified `ol/layer/Layer~Layer`.
 * You probably want getInfoForExtent (when working with a map) or getFeatureResponse (when not).
 * Also be aware that requests for e.g. boundary layers for an entire country are likely to be slow and memory-intensive for the WFS service.
 *
 * ```
 * import { getInfo } from 'ol-ishare/info';
 * ```
 *
 * @param {ol/layer/Layer~Layer} layer Layer to be queried - expected to be WFS- or WMS-based
 * @param {ol/format/filter} [filter=None] OpenLayers Filter object to apply to the layer.
 *  (Geometry names will be set automatically.)
 * @param {Object} [params] Additional key-values to pass to WFS requests
 * @returns {Promise} Returns a Promise for each infoLayer resolving to response from WFS service
 */
function getInfo(layer, filter, params) {
  var outputFormat;
  var filterNode;
  if (params) {
    var outputFormatKey = Object.keys(params).find(
      (key) => key.toUpperCase() === 'OUTPUTFORMAT'
    );
    if (outputFormatKey) {
      outputFormat = params[outputFormatKey];
      delete params[outputFormatKey];
    }
  }
  if (filter) {
    filterNode = writeFilter(filter, DEFAULT_FILTER_VERSION);
  }
  return info.getFeatureResponse(
    layer.getSource().getUrl(),
    layer.get('iShare:layerName'),
    filterNode,
    params,
    outputFormat
  );
}

/**
 * Requests features within specified extent for the specified `ol/layer/Layer~Layer`.
 * Typically used with current visible extent of map, or the extent of the entire iShare layer/profile.
 * Without specifying an OUTPUTFORMAT parameter, get response text and run through `info.wfsGetFeatureReader` to get OpenLayers Features.
 *
 * ```
 * import { getInfoForExtent } from 'ol-ishare/info';
 * ```
 *
 * @param {ol/layer/Layer~Layer} layer Layer to be queried - expected to be WFS- or WMS-based
 * @param {ol/extent} extent Array of extent coordinates
 * @param {ol/format/filter} [filter=None] OpenLayers Filter object to apply to the layer, this will be applied as well as a filter for the extent
 * @param {Object} [params] Additional key-values to pass to WFS requests
 * @returns {Promise} Returns a Promise resolving to `Fetch.Response` from WFS service
 */
function getInfoForExtent(layer, extent, filter, params) {
  var projection = layer.getSource().getProjection();
  var extentFilter = new filterBBOX(
    layer.get('iShare:config').geometry.field,
    extent,
    projection ? projection.getCode() : 'EPSG:27700'
  );
  var viewParamsBBOX = 'bbox("","' + extent.join(',') + '")';
  params = params || {};
  var viewParamsKey = Object.keys(params).find(
    (key) => key.toUpperCase() === 'VIEWPARAMS'
  );
  if (viewParamsKey && params[viewParamsKey]) {
    var viewParams = params[viewParamsKey].split(';');
    if (
      viewParams.findIndex(function (viewParam) {
        return viewParam.startsWith('bbox');
      }) === -1
    ) {
      viewParams.push(viewParamsBBOX);
      params[viewParamsKey] = viewParams.join(';');
    }
    // don't replace existing VIEWPARAMS 'bbox()'
  } else {
    params.VIEWPARAMS = viewParamsBBOX;
  }
  if (filter && filter instanceof Filter) {
    filter = filterAnd(extentFilter, filter);
  } else {
    filter = extentFilter;
  }
  return info.getInfo(layer, filter, params);
}

export {
  infoResultsToHtml,
  infoResultToHtml,
  getInfoTolerance,
  getInfoAtPoint,
  getInfoForExtent,
  getInfo,
  getInfoLayers,
  getWfsUrl,
  getWfsGetFeatureUrl,
  getFeature,
  getFeatureResponse,
  getFeatureByValue,
  wmsGetFeatureInfoReader,
  wfsGetFeatureReader,
  createFeatureReader
};
