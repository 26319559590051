/**
 * Class for assisting with making measurements on an OpenLayers map
 *
 * @module ol-ishare/interaction/measure
 */

import Draw from 'ol/interaction/Draw';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import { Collection } from 'ol';
import {
  Fill as FillStyle,
  Stroke as StrokeStyle,
  Style,
  Text as TextStyle
} from 'ol/style';
import { getArea, getLength } from 'ol/sphere';
import { unByKey } from 'ol/Observable';

function validateOptions(options) {
  const validTypes = ['LineString', 'Polygon'];
  if (!options.type) {
    throw Error('Measure: Geometry type not specified');
  }
  if (!validTypes.includes(options.type)) {
    throw Error(
      'Measure: Geometry type ' + options.type + ' not valid for measuring.'
    );
  }
}

const DEFAULT_FEATURE_STYLE = [
  new Style({
    fill: new FillStyle({
      color: 'rgba(0, 20, 132, 0.05)'
    })
  }),
  new Style({
    stroke: new StrokeStyle({
      color: 'rgba(0, 20, 132, 0.5)',
      lineDash: [10, 10],
      width: 5
    })
  }),
  new Style({
    stroke: new StrokeStyle({
      color: 'rgba(255, 255, 255, 0.8)',
      lineDash: [10, 10],
      width: 2
    })
  })
];

const DEFAULT_SKETCH_STYLE = [
  new Style({
    stroke: new StrokeStyle({
      color: 'rgba(0, 20, 132, 0.2)',
      lineDash: [10, 10],
      width: 3
    })
  })
];

function extractExtras(options) {
  const extras = {};

  const keys = ['persist'];

  keys.forEach(function (k) {
    extras[k] = options[k];

    delete options[k];
  });

  extras.persist = extras.persist !== false; // default to true

  return extras;
}

/**
 * Extracts features with measurements from vector Source object, OpenLayers collection
 * @param {Array<ol/Source/Vector>|ol/Collection<ol/Feature>} sourceOrCollection Vector source or collection of features
 * @return {Array<ol/Feature>} All features found with measurements
 */

function getMeasurementFeatures(sourceOrCollection) {
  const isVectorSource = sourceOrCollection instanceof VectorSource;
  const isCollection = sourceOrCollection instanceof Collection;
  if (!isVectorSource && !isCollection) {
    return [];
  }
  const measuredFeatures = [];
  let iterable;
  if (isVectorSource) {
    iterable = sourceOrCollection.getFeatures();
  } else if (isCollection) {
    iterable = sourceOrCollection.getArray();
  }

  if (iterable) {
    iterable.forEach(function (feature) {
      const props = feature.getProperties();
      if (props.hasOwnProperty('measurement')) {
        measuredFeatures.push(feature);
      }
    });
  }
  return measuredFeatures;
}

/**
 * Removes features with measurements from vector Source object, OpenLayers collection
 * @param {Array<ol/Source/Vector>|ol/Collection<ol/Feature>} sourceOrCollection Vector source or collection of features
 * @return {Array<ol/Feature>} removed features
 */
function clearMeasurementFeatures(sourceOrCollection) {
  if (!sourceOrCollection) {
    return [];
  }
  const measuredFeatures = getMeasurementFeatures(sourceOrCollection);
  const removedFeatures = [];
  if (sourceOrCollection instanceof VectorSource) {
    for (const feature of measuredFeatures) {
      if (sourceOrCollection.hasFeature(feature)) {
        sourceOrCollection.removeFeature(feature);
        removedFeatures.push(feature);
      }
    }
  } else if (sourceOrCollection instanceof Collection) {
    for (const feature of measuredFeatures) {
      const removed = sourceOrCollection.remove(feature);
      if (removed) {
        removedFeatures.push(removed);
      }
    }
  }
  return removedFeatures;
}

/**
 * Measure interaction for measuring lengths or areas on a map.
 *
 * While the measurement is being drawn, a temporary 'sketch' will be shown on an overlay vector layer. To style this use `.getOverlay().setStyle()`.
 * @extends {ol/interaction/Draw}
 * @param {Object} options {@link ol/interaction/Draw} options.
 *   Note that like {@link ol/interaction/Draw}, the `options.type` 'option' is required.
 *   Extended with the following extra settings
 * @param {Boolean} [options.persist=true] Whether to persist all measurements (default `Draw` behaviour) or to clean up previous measurements when starting a new one
 */
class Measure extends Draw {
  #persist;
  constructor(options) {
    let interim;
    options = Object.assign({}, options);
    validateOptions(options);
    if (!options.style) {
      options.style = DEFAULT_SKETCH_STYLE;
    }

    const extras = extractExtras(options);

    super(options);

    this.#persist = extras.persist;

    //events
    this.on('drawstart', function (evt) {
      let segments = 0;
      const interaction = this;
      if (!this.#persist) {
        const source = this.getSource();
        const features = this.getFeaturesCollection();
        clearMeasurementFeatures(source);
        clearMeasurementFeatures(features);
      }
      interim = evt.feature.on('change', function (evt) {
        const allCoords = evt.target
          .getGeometry()
          .getCoordinates()
          .reduce(function (all, coords) {
            return all.concat(coords);
          }, []);
        if (allCoords.length > segments) {
          segments = allCoords.length;
          /**
           * Fired when the measurement sketch feature has a new segment added to it
           * @event module:ol-ishare/interaction/measure~Measure#measurementinterim
           * @type {Object}
           * @property {string} type `'measurementinterim'`
           * @property {module:ol-ishare/interaction/measure~Measure} target The Measure Interaction that triggered the event
           * @property {ol/Feature} feature Sketch feature object with additional measurement properties.
           */
          interaction.dispatchEvent({
            type: 'measurementinterim',
            target: interaction,
            feature: Measure.addMeasurements(evt.target)
          });
        }
        /**
         * Fired when the measurement sketch updates - typically whenever the mouse moves while Measure is active.
         * @event module:ol-ishare/interaction/measure~Measure#measurementdraft
         * @type {Object}
         * @property {string} type `'measurementdraft'`
         * @property {module:ol-ishare/interaction/measure~Measure} target The Measure Interaction that triggered the event
         * @property {module:ol/Feature~Feature} feature The sketch feature with measurement properties
         *
         */
        interaction.dispatchEvent({
          type: 'measurementdraft',
          target: interaction,
          feature: Measure.addMeasurements(evt.target)
        });
      });
    });

    this.on('drawend', function (evt) {
      unByKey(interim);

      /**
       * Fired when the measurement feature is finished
       * @event module:ol-ishare/interaction/measure~Measure#measurementfinal
       * @type {Object}
       * @property {string} type `'measurementfinal'`
       * @property {module:ol-ishare/interaction/measure~Measure} target The Measure Interaction that triggered the event
       * @property {module:ol/Feature~Feature} feature The sketch feature with measurement properties
       *
       */
      this.dispatchEvent({
        type: 'measurementfinal',
        target: this,
        feature: Measure.addMeasurements(evt.feature)
      });
    });
  }

  /**
   *  @return {Boolean} Whether previous measurements stay in the associated source or collection (if configured) when a new measurement is started
   */
  getPersistance() {
    return this.#persist;
  }

  /**
   *  @return {ol/Source/Vector} The vector source in use (if configured)
   */
  getSource() {
    return this.source_;
  }

  /**
   *  @return {ol/Collection<ol/Feature>} The collection of features in use (if configured)
   */
  getFeaturesCollection() {
    return this.features_;
  }

  /**
   * Formats length value, converts metres to kilometres when value is large.
   * @param {Number} length Length in metres
   * @return {string} The formatted length.
   */
  static formatLength(length) {
    var output;
    if (length > 300) {
      output = Math.round(length / 10) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
  }

  /**
   * Formats area value, converts square metres to square kilometres when value is large.
   * @param {Number} area Area in square metres
   * @return {string} Formatted area.
   */
  static formatArea(area) {
    var output;
    if (area > 300000) {
      output = Math.round(area / 10000) / 100 + ' ' + 'sq. km';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'sq. m';
    }
    return output;
  }

  /**
   * Uses `Measure` formatting static methods to add measurement properties to a feature
   * @param {ol/Feature} feature Feature for which to calculate and update with measurements
   * @return {ol/Feature} Feature with added measurement properties, available via {ol/Feature#getProperties}:
   * `measurement`, will either be `'area'` or `'length'`.
   *
   * Additional 'area' measurements:
   * `area` - numeric area value
   * `formattedArea` - formatted area text
   * `perimeter` - numeric length of perimeter
   * `formattedPerimeter` - formatted perimeter text
   *
   * Additional 'length' measurements:
   * `length` - numeric length value
   * `formattedLength` - formatted length text
   */
  static addMeasurements(feature) {
    const geom = feature.getGeometry();
    if (geom instanceof Polygon) {
      const perimeter = getLength(
        new LineString(geom.getLinearRing(0).getCoordinates())
      );
      const area = getArea(geom);
      feature.setProperties({
        measurement: 'area',
        area: area,
        formattedArea: Measure.formatArea(area),
        perimeter: perimeter,
        formattedPerimeter: Measure.formatLength(perimeter)
      });
    } else if (geom instanceof LineString) {
      const length = getLength(geom);
      feature.setProperties({
        measurement: 'length',
        length: length,
        formattedLength: Measure.formatLength(length)
      });
    }
    return feature;
  }
}

/**
 * Creates an OpenLayers {@link ol/style/Text~Text text style object} containing a formatted measurement value placed in the middle of the feature's geometry (or nearest internal point)
 * @param {ol/Feature} feature Feature for which to calculate and update with measurements
 * @param {Object} [formatters] Optional object for overriding default text formatting functions
 * @param {Function} [formatters.area] Turn area numeric value into text. Defaults to {@link module:ol-ishare/interaction/measure~Measure.formatArea }
 * @param {Function} [formatters.length] Turn length numeric value into text. Defaults to {@link module:ol-ishare/interaction/measure~Measure.formatLength }
 * @return {ol/style/Text~Text} Text style object containing static text property for measurement
 */
function generateMeasurementText(
  feature,
  formatters = { area: Measure.formatArea, length: Measure.formatLength }
) {
  const geom = feature.getGeometry();
  const type = geom.getType();
  let measureText;
  if (type === 'LineString') {
    measureText = new TextStyle({
      text: formatters.length(getLength(geom)),
      placement: 'line'
    });
  } else if (type === 'Polygon' || type === 'Circle') {
    measureText = new TextStyle({
      text: formatters.area(getArea(geom)),
      placement: 'point'
    });
  }
  return measureText;
}

export {
  Measure,
  DEFAULT_FEATURE_STYLE,
  DEFAULT_SKETCH_STYLE,
  getMeasurementFeatures,
  clearMeasurementFeatures,
  generateMeasurementText
};
