/**
 * @module ol-ishare/control/gazetteer
 */

// TODO Tests

// TODO Default logic for zooming to extent (bbox) or point (coord);
//      there would need to be someway of overriding or disabling the default

import Control from 'ol/control/Control';
import { Gazetteer as UiGazetteer } from '../ui/gazetteer.js';

/**
 * Autocomplete Gazetteer search map control
 * @class
 * @classdesc Auto-complete address search control for use with an OpenLayers map
 * @param {Object} options Gazetteer options
 * @param {String} options.target DOM element that the control will be created within
 * @param {String} options.iShareUrl Base URL for iShare Web app. If `iShareUrl` and `profile` options are not supplied then one or more {@link module:ol-ishare/ui/gazetteer~GazetteerSource} instances must be specified via the `sources` option.
 * @param {String} options.profile Name of profile that the search is associated with. If `iShareUrl` and `profile` options are not supplied then one or more {@link module:ol-ishare/ui/gazetteer~GazetteerSource} instances must be specified via the `sources` option.
 * @param {Array<module:ol-ishare/ui/gazetteer~GazetteerSource>} options.sources List of GazetteerSource instances that will be queried for results. See {@link module:ol-ishare/search~AddressSearch} and {@link module:ol-ishare/search~LayerSearch} for examples of classes that implement GazetteerSource
 * @param {String} options.placeholder Placeholder text displayed by the input
 */
var Gazetteer = (function (Control) {
  function Gazetteer(opt_options) {
    var options = opt_options || {};

    var element = document.createElement('div');
    element.className = 'oli-gazetteer ol-unselectable ol-control';

    Control.call(this, {
      element: element,
      target: options.target
    });

    this.gaz = new UiGazetteer({
      iShareUrl: options.iShareUrl,
      profile: options.profile,
      sources: options.sources,
      placeholder: options.placeholder,
      target: element
    });

    var that = this;
    this.gaz.on('select', function (evt) {
      that.dispatchEvent({ type: 'select', result: evt.result });
    });
  }

  if (Control) Gazetteer.__proto__ = Control;
  Gazetteer.prototype = Object.create(Control && Control.prototype);
  Gazetteer.prototype.constructor = Gazetteer;

  return Gazetteer;
})(Control);

export { Gazetteer };
