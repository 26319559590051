/**
 * iShare Maps LiteMap, creates a map based on a profile and enables an info interaction.
 *
 * ```
 * import { LiteMap } from 'ol-ishare/litemap'
 * ```
 * @module ol-ishare/litemap
 */

import BaseObject from 'ol/Object';
import version from './version.js';
import { AddressSearch } from './search.js';
import { DragRotate, defaults as interactionDefaults } from 'ol/interaction';
import { InfoPopup } from './interaction/infopopup.js';
import { defaults as controlDefaults } from 'ol/control';
import {
  createBaseGroup,
  createGroupsAndOverlays,
  createMap,
  createView
} from './map.js';
import {
  createEmpty as createEmptyExtent,
  extend as extendExtent
} from 'ol/extent';
import { defaultsDeep } from 'lodash';
import { getBaseConfig, getLayerConfigs, getProfile } from './profile.js';
import { inherits } from './olutil.js';

var DEFAULT_LAYER_OPTION = { name: '', visible: true, opacity: 1 };

/**
 * Options for iShare Map object
 * @typedef MapOptions
 * @type {Object}
 * @property {String} iShareUrl Base URL for iShare Web app
 * @property {String} profile Name of profile to load
 * @property {String} target DOM element that the map will be created within
 * @property {Object} [view] Initial view as `{easting: Number, northing: Number, zoom: Number}`
 * @property {Array<String>|String|Array<module:ol-ishare/litemap~LayerOption>} [layers] Layers to be visible at map creation, one of:
 *      an array of layer names;
 *      a comma-separated list of layer names; or
 *      an array of `LayerOption` objects.
 *  If only names are specified then layers are added using the default values in `LayerOption` overriding layer settings in the profile
 * @property {String} [defaultLayers=initial] In addition to those specified in `layers` option:
 *      'all' - create all layers, visibility set by layer config in the profile;
 *      'initial' - create and enable those with `'initiallyVisible':true` (default); or
 *      'none' - create no additional layers.
 * @property {String} [baseMap] Name of basemap to load on startup (if not set, uses the default in specified profile)
 * @property {ol/PluggableMap~MapOptions} [map] Optional OpenLayers Map Options
 * @property {ol/Collection<ol/interaction/Interaction>} [map.interactions] Collection of interactions to be added to the map

 */

/**
 * MapOptions layer configuration
 * @typedef LayerOption
 * @type {Object}
 * @property {String} name Name of layer to add to the map
 * @property {Boolean} [visible=true] Whether the layer should be visible when the map is created
 * @property {Number} [opacity=1] How opaque the layer should be at load
 */

/**
 * Create and return a new LiteMap instance
 *
 * @class
 * @param {module:ol-ishare/litemap~MapOptions} options LiteMap options
 */
var LiteMap = function (options) {
  this.version = version;
  BaseObject.call(this);

  this.baseUrl = options.iShareUrl;

  var originalLayers = [];
  if (options.hasOwnProperty('layers')) {
    originalLayers = Array.isArray(options.layers)
      ? options.layers
      : options.layers.split(',');
  }

  // replace the specified array with one using only new LayerOption objects
  // (in case the original array is being used outside of LiteMap)
  options.layers = originalLayers
    .map(function (item) {
      var layerOption = Object.assign({}, DEFAULT_LAYER_OPTION);
      if (typeof item === 'string') {
        layerOption.name = item;
      } else if (
        Object.prototype.toString.call(item) === '[object Object]' &&
        item.hasOwnProperty('name')
      ) {
        layerOption.name = item.name;
        if (item.hasOwnProperty('visible')) {
          layerOption.visible = item.visible !== false; // set to true if not explicitly set to false
        }
        if (item.hasOwnProperty('opacity')) {
          layerOption.opacity = Math.min(Math.max(item.opacity, 0), 1);
        }
      } else {
        layerOption = null;
        console.warn('Invalid layer option specified');
        console.info(item);
      }
      return layerOption;
    })
    .filter(function (layerOption) {
      return layerOption !== null;
    });

  const ol_opts = options.map || {};
  if (!ol_opts.hasOwnProperty('interactions')) {
    ol_opts.interactions = LiteMap.defaultInteractions();
  }
  if (!ol_opts.hasOwnProperty('controls')) {
    ol_opts.controls = LiteMap.defaultControls();
  }
  /**
   * @property {ol/Map~Map} map OpenLayers Map instance
   */
  this.map = createMap(options.target, ol_opts);

  // TODO make loading a profile optional to allow users to create a
  // litemap then use loadProfile to load a custom profile
  function setProfile(profile) {
    // If we've been passed options.view use it otherwise fallback to
    // the initialView
    profile.view = options.view || profile.initialView;

    options.defaultLayers = options.defaultLayers || 'initial';
    if (typeof options.defaultLayers === 'boolean') {
      //backwards compatibility with old 'createAllLayers' option
      options.defaultLayers = options.defaultLayers ? 'all' : 'initial';
    }

    getLayerConfigs(profile.layerGroups).forEach(function (l) {
      var layerOption = options.layers.filter(function (lo) {
        return lo.name.toLowerCase() === l.layerName.toLowerCase();
      })[0];
      if (layerOption) {
        l.hidden = false;
        l.initiallyVisible = layerOption.visible;
        l.opacity = layerOption.opacity;
      } else if (options.defaultLayers === 'none') {
        l.hidden = true;
      } else if (options.defaultLayers === 'initial') {
        l.hidden = !l.initiallyVisible;
      }
    });

    if (options.baseMap) {
      var baseConfig = getBaseConfig(profile.baseMaps, options.baseMap);
      if (baseConfig) {
        profile.defaultBaseMap = baseConfig.mapName;
      }
    }

    var profile_opts = {
      createOnlyVisibleLayers: false,
      useProfileLayerOrder: true
    };

    this.loadProfile(profile, profile_opts);
    this.dispatchEvent({ type: 'load', lite: this });
  }

  getProfile(this.baseUrl, options.profile).then(setProfile.bind(this));

  return this;
};

inherits(LiteMap, BaseObject);

/**
 * Load a given profile Object, once loaded dispatch a `profileload` event.
 * @param {module:ol-ishare/profile~Profile} profile iShare profile as provided by {@link module:ol-ishare/profile~getProfile}
 * @param {Object} options Options that affect how the profile is loaded
 * @param {Boolean} options.createOnlyVisibleLayers When `true` only create groups and layers that are initiallyVisible (defaults to `true`)
 */
LiteMap.prototype.loadProfile = function (profile, options) {
  /**
   * @property {module:ol-ishare/profile~Profile} profile Current profile instance
   */
  this.profile = profile;

  options.createOnlyVisibleLayers = options.hasOwnProperty(
    'createOnlyVisibleLayers'
  )
    ? options.createOnlyVisibleLayers
    : true;

  this.map.getLayers().clear();

  // Create all base maps and add to a base maps group
  var baseMapGroup = createBaseGroup(profile);
  this.map.addLayer(baseMapGroup);

  var groupLayers = createGroupsAndOverlays(
    profile,
    options.createOnlyVisibleLayers,
    options.useProfileLayerOrder !== false
  );
  groupLayers.forEach(function (groupLayer) {
    this.map.addLayer(groupLayer);
  }, this);

  var view = createView(profile, this.map.getSize());
  this.map.setView(view);

  this.dispatchEvent({ type: 'profileload' });
};

/**
 * Get an address search instance compatible with Gazetteer control pre-configured with
 * setting relevant to the current profile.
 * @param {Object} [options] Options
 * @param {Number} [options.pageSize] Number of addresses to return, see `AddressSearch` for default and max values
 * @returns {module:ol-ishare/search~AddressSearch} Address search instance
 */
LiteMap.prototype.getAddressSearch = function (options) {
  var p = this.profile.projection;
  options = options || {};
  return new AddressSearch({
    iShareUrl: this.baseUrl,
    profile: this.profile.mapName,
    srs: p.SRS + ':' + p.SRID,
    pageSize: options.pageSize
  });
};

/**
 * (Re)sets the view to that set in the current profile
 * @returns {ol/extent~Extent} Actual extent of updated map view (after accounting for fixed zoom levels)
 */
LiteMap.prototype.resetView = function () {
  var view = createView(this.profile, this.map.getSize(), true);
  this.map.getView().setCenter(view.getCenter());
  this.map.getView().setResolution(view.getResolution());
  return this.map.getView().calculateExtent(this.map.getSize());
};

/**
 * Change the view of the map to centre on the supplied features at a resolution that shows them all in context
 * @param {Array<ol/Feature~Feature>} features Features around which the map should be redrawn
 * @param {ol/View~FitOptions} options Options passed to `ol/View~View#fit`, by default the padding
 *   property is set to `[25, 25, 25, 25]`
 * @returns {ol/extent~Extent} Actual extent of updated map view (after accounting for fixed zoom levels)
 */
LiteMap.prototype.zoomToFeatures = function (features, options) {
  options = options || {};
  options.padding = options.padding || [25, 25, 25, 25];
  var bounds = features.reduce(function (extent, feature) {
    var geometry = feature.getGeometry();
    if (geometry) {
      return extendExtent(extent, geometry.getExtent());
    } else {
      return extent;
    }
  }, createEmptyExtent());
  this.map.getView().fit(bounds, options);
  return this.map.getView().calculateExtent(this.map.getSize());
};

/**
 * Change the view of the map to show the full extent of the profile
 * @returns {ol/extent~Extent} Actual extent of updated map view (after accounting for fixed zoom levels)
 */
LiteMap.prototype.zoomToProfile = function () {
  this.map.getView().fit(this.profile.extent);
  return this.map.getView().calculateExtent(this.map.getSize());
};
/**
 * Get the interactions included in maps by default. Specific interactions can be
 * excluded by setting the appropriate option to false in the constructor
 * options, but the order of the interactions is fixed.  If you want to specify
 * a different order for interactions, you will need to create your own
 * {@link ol/interaction/Interaction} instances and insert
 * them into a {@link ol/Collection} in the order you want
 * before creating your {@link ol/Map~Map} instance. The default set of
 * interactions are the OpenLayers default (see http://openlayers.org/en/latest/apidoc/module-ol_interaction.html)
 *  minus: {@link ol/interaction/DragRotate~DragRotate} and {@link ol/interaction/PinchRotate~PinchRotate}
 *  plus: {@link module:ol-ishare/interaction/infopopup~InfoPopup}.
 * @param {ol/interaction/Interaction~DefaultsOptions=} opt_options Default options.
 * @return {ol/Collection<ol/interaction/Interaction>}
 * A collection of interactions to be used with the {@link ol/Map~Map} constructor's `interactions` option.
 */
LiteMap.defaultInteractions = function (opt_options) {
  var options = defaultsDeep({}, opt_options, {
    dragRotate: false,
    pinchRotate: false,
    infoPopup: true
  });
  var interactions = interactionDefaults(options);

  if (options.dragRotate !== true) {
    // for some reason `dragRotate` isn't present in the default interactions options
    interactions.forEach(function (inter) {
      if (inter instanceof DragRotate) {
        interactions.remove(inter);
      }
    });
  }

  if (options.infoPopup === true) {
    interactions.push(new InfoPopup());
  } else if (options.infoPopup instanceof InfoPopup) {
    interactions.push(options.infoPopup);
  }
  return interactions;
};
/**
 * Get the controls and their settings included in maps by default. Specific controls can be
 * excluded by setting the appropriate option to false in the constructor
 * options, but the order of the controls is fixed.  If you want to specify
 * a different order for controls, you will need to create your own
 * {@link ol/control/Control} instances and insert
 * them into a {@link ol/Collection} in the order you want
 * before creating your {@link ol/Map~Map} instance. The default set of
 * controls are the OpenLayers default (see https://openlayers.org/en/latest/apidoc/module-ol_control.html).
 * Changes to default configuration:
 *  `attributionOptions: { collapsible: false }`
 * @param {ol/control/Control~DefaultsOptions=} opt_options Default options.
 * @return {ol/Collection<ol/control/Control>}
 * A collection of controls to be used with the {@link ol/Map~Map} constructor's `control` option.
 */
LiteMap.defaultControls = function (opt_options) {
  var options = defaultsDeep({}, opt_options, {
    attributionOptions: { collapsible: false }
  });
  var controls = controlDefaults(options);
  return controls;
};

export { LiteMap };
