/**
 * @module ol-ishare/ui/gazetteer
 */

// TODO Should GazetteerSource instances provide some info such as:
//      * Suggested resolution for zooming to coord
//      * Name of the ID column
//      Could be added to GazetteerResult object via a `metadata` property?

// TODO It might be nice if there was an easy way to access either the full
//      feature or at least the attributes for a LayerSearch GazetteerResult object
//      Could be added to GazetteerResult object via an optional `detail` property?

/**
 * Interface for GazetteerSource instances.
 * @interface GazetteerSource
 */

/**
 * Search for results based on the provided query string, returns a Promise which will resolve to
 * an Array of {@link module:ol-ishare/ui/gazetteer~GazetteerResult} objects or an error
 *
 * @function
 * @name module:ol-ishare/ui/gazetteer~GazetteerSource#search
 * @param {string} query String to search for
 * @returns {Promise<Array<module:ol-ishare/ui/gazetteer~GazetteerResult>>} Promise that resolves to an Array of GazetteerResult objects or an Error
 */

/**
 * @typedef GazetteerResult
 * @property {string} id Unique ID of the result (UPRN, planning application reference etc.)
 * @property {Array<number>} coord Coordinate of result e.g. `[x: number, y: number]`
 * @property {Array<number>} bbox Bounding box coordinates of the result e.g. `[x_min: number, y_min: number, x_max: number, y_max: number]`
 * @property {string} srs Spatial reference system code of `coord` or `bbox`
 * @property {string} text Result text
 * @property {string} formatted Result text with matching part of text highlighted via html
 */

import Observable from 'ol/Observable';
import accessibleAutocomplete from 'accessible-autocomplete';
import { AddressSearch } from '../search.js';

/**
 * Autocomplete Gazetteer search
 * @class
 * @classdesc Standalone auto-complete search
 * @param {Object} options Gazetteer options
 * @param {String} options.target DOM element that the control will be created within
 * @param {String} options.iShareUrl Base URL for iShare Web app. If `iShareUrl` and `profile` options are not supplied then one or more {@link module:ol-ishare/ui/gazetteer~GazetteerSource} instances must be specified via the `sources` option.
 * @param {String} options.profile Name of profile that the search is associated with. If `iShareUrl` and `profile` options are not supplied then one or more {@link module:ol-ishare/ui/gazetteer~GazetteerSource} instances must be specified via the `sources` option.
 * @param {Array<module:ol-ishare/ui/gazetteer~GazetteerSource>} options.sources List of GazetteerSource instances that will be queried for results. See {@link module:ol-ishare/search~AddressSearch} and {@link module:ol-ishare/search~LayerSearch} for examples of classes that implement GazetteerSource
 * @param {String} options.placeholder Placeholder text displayed by the input
 */
var Gazetteer = (function (_super) {
  function Gazetteer(opt_options) {
    _super.call(this);
    var options = opt_options || {};

    // Increament instance count to allow us to generate unique id's for elements
    Gazetteer._instanceCount += 1;

    if (options.target && options.target.nodeType !== undefined) {
      // We have an element
      this.element = options.target;
    } else {
      // Assume we've been passed an ID, get the element by ID
      this.element = document.getElementById(options.target);
    }
    this.element.classList.add('oli-gazetteer');

    // Generate an ID for the input
    this.inputId = 'oli-gazetteer-' + Gazetteer._instanceCount;

    this.sources = options.sources || [];

    if (this.sources.length === 0) {
      // Default to creating an addresss search if sources are not
      // specified and iShareUrl and profile are
      if (options.iShareUrl && options.profile) {
        this.sources.push(
          new AddressSearch({
            iShareUrl: options.iShareUrl,
            profile: options.profile
          })
        );
      } else {
        console.warn(
          'Gazetteer - No sources specified and iShareUrl and profile options missing...'
        );
      }
    }

    this.requestCounter = 0;
    this.handleConfirm = this.handleConfirm.bind(this);
    this.search = this.search.bind(this);

    function inputValueTemplate(result) {
      return result && result.text;
    }

    function suggestionTemplate(result) {
      return result && (result.formatted || result.text);
    }

    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     * Copied from https://davidwalsh.name/javascript-debounce-function
     * @param {Function} func Function to debounce
     * @param {Number} wait ms to wait
     * @param {Boolean} immediate trigger the function on the leading edge
     * @returns {undefined}
     * @private
     */
    function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
          args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }

    accessibleAutocomplete({
      element: this.element,
      id: this.inputId,
      source: debounce(this.search, 500, false),
      asyncResults: true,
      autoselect: false,
      confirmOnBlur: false,
      minLength: 2,
      placeholder: options.placeholder,
      onConfirm: this.handleConfirm,
      templates: {
        inputValue: inputValueTemplate,
        suggestion: suggestionTemplate
      }
    });
  }

  if (_super) Gazetteer.__proto__ = _super;
  Gazetteer.prototype = Object.create(_super && _super.prototype);
  Gazetteer.prototype.constructor = Gazetteer;

  Gazetteer.prototype.handleConfirm = function handleConfirm(result) {
    if (result) {
      this.dispatchEvent({ type: 'select', result: result });
      var inputId = this.inputId;
      setTimeout(function () {
        document.getElementById(inputId).blur();
      }, 0);
    }
  };

  Gazetteer.prototype.search = function search(query, populateResults) {
    this.requestCounter += 1;
    var requestNum = this.requestCounter;
    var self = this;
    var ps = this.sources.map(function (source) {
      return source.search(query);
    });
    Promise.all(ps)
      .then(function (results) {
        // If the current requestNum doesn't match then
        // we simply skip it as it's been superseeded by
        // a more recent request
        if (requestNum === self.requestCounter) {
          // Flattern results into a single Array
          results = results.reduce(function (acc, val) {
            return acc.concat(val);
          }, []);
          populateResults(results);
        }
      })
      .catch(function (err) {
        if (requestNum === self.requestCounter) {
          populateResults([]);
        }
      });
  };

  Gazetteer._instanceCount = 0;

  return Gazetteer;
})(Observable);

export { Gazetteer };
