/**
 * @module ol-ishare/control/layerswitcher
 */

import OlLayerSwitcher from 'ol-layerswitcher';

function handleImageError() {
  console.warn('Image failed to load: ' + this.src);
  this.classList.add('fail');
}

var renderLayer = OlLayerSwitcher.renderLayer_;

/**
 * **Static** Render all layers that are children of a group.
 * @private
 * @param {ol/Map~Map} map The map instance.
 * @param {ol/layer/Base~BaseLayer} lyr Layer to be rendered (should have a title property).
 * @param {Number} idx Position in parent group list.
 * @param {Object} options LayerSwitcher options
 * @param {Function} render Callback to call when layer state changes (normally LayerSwitcher.RenderPanel)
 * @returns {HTMLElement} Layer list item element
 */
OlLayerSwitcher.renderLayer_ = function (map, lyr, idx, options, render) {
  var li = renderLayer(map, lyr, idx, options, render);
  if (lyr.get('type') === 'base') {
    return li;
  }

  if (options.legends === 'static') {
    var input = li.querySelector('input');
    input && input.parentElement === li && li.removeChild(input);
  }
  var showLegends =
    options.legends === 'static' || options.legends === 'dynamic';

  if (showLegends && (!lyr.getLayers || lyr.get('combine'))) {
    if (lyr.get('visible') && lyr.getSource) {
      var source = lyr.getSource();
      if (source.getLegendUrl) {
        var img = document.createElement('img');
        img.classList.add('legend');
        img.addEventListener('error', handleImageError);
        img.src = source.getLegendUrl(map.getView().getResolution(), {
          VERSION: source.getParams().VERSION
        });
        li.appendChild(img);
      }
    }
  }

  return li;
};
/**
 * @extends {ol/control/Control~Control}
 * @extends {ol-layerswitcher~LayerSwitcher}
 * @param {Object} opt_options Control options, extends {@link ol/control/Control} options adding:
 * @param {boolean} [opt_options.startActive=false] Whether panel is open when created.
 * @param {String} [opt_options.activationMode='mouseover'] Event to use on the button to collapse or expand the panel:
 *   `'mouseover'` - the layerswitcher panel stays expanded while button or panel are hovered;
 *   `'click'` - a click on the button toggles the layerswitcher visibility.
 * @param {String} [opt_options.collapseLabel='»'] Text label to use for the expanded layerswitcher button. E.g.:
 *   `'»'` or `'\u00BB'`, `'-'` or `'\u2212'`. Not visible if activation mode is `'mouseover'`
 * @param {String} [opt_options.label=''] Text label to use for the collapsed layerswitcher button. E.g.:
 *   `''`, `'«'` or `'\u00AB'`, `'+'`.
 * @param {String} [opt_options.tipLabel='Legend'] the button tooltip.
 * @param {String} [opt_options.collapseTipLabel] the button tooltip when the panel is open.
 * @param {String} [opt_options.groupSelectStyle='children'] what to add to group labels:
 *   `'none'` - groups don't get a checkbox;
 *   `'children'` - groups have a checkbox and affect child visibility or;
 *   `'group'` - groups have a checkbox but do not alter child visibility (like QGIS).
 * @param {boolean} [opt_options.reverse=true] reverse the layer order.
 * @param {legends} [opt_options.legends='none'] Whether to display legend images with the layer name:
 *    `'none'` - layers have no legend image;
 *    `'dynamic'` - legend image displayed with checkbox;
 *    `'static'` - only legend image shown, no checkbox.
 * @classdesc LayerSwitcher control. Lists all overlay layers with optional legend images together with base maps. Extends {@link ol-layerswitcher~LayerSwitcher} adding legend images.
 * @class
 * @name LayerSwitcher
 */
class LayerSwitcher extends OlLayerSwitcher {
  constructor(opt_options) {
    var options = opt_options || {};
    super(options);
    this.legends = options.legends;
    this.element.classList.add('legends-' + this.legends);
  }
  /**
   * @private
   */
  renderPanel() {
    this.dispatchEvent({ type: 'render' });
    LayerSwitcher.renderPanel(this.getMap(), this.panel, {
      groupSelectStyle: this.groupSelectStyle,
      reverse: this.reverse,
      legends: this.legends
    });
    this.dispatchEvent({ type: 'rendercomplete' });
  }
}

export { LayerSwitcher };
