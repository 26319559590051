import Store from '../store/Store.js';
import { Observable } from 'ol';
import { isEqual, isString, throttle } from 'lodash';

const VALID_KEYS = Object.freeze([]);

/**
 * State base class
 *
 * The state being tracked, and the methods by which it is updated by a given sub-class, will be particular
 *   to the application or instance type for which that class has been created, but they must adhere to
 *   the restrictions defined here.
 *
 * This base class encapsulates the core mechanisms of creating and managing an abstract state object and
 *   transferring to/recalling from a Store instance.
 *
 * The state is essentially a dictionary where:
 *     Keys must be strings, and are set using the static {@link module:ol-ishare/state.State.VALID_KEYS VALID_KEYS} property of the class.
 *     Stores mandate that all values must be able to be cast to strings;
 *     and the state will not assign values by reference with copies that will be only of types understood by JSON
 *             (i.e. no methods, events, etc. not present in base types).
 * @memberof module:ol-ishare/state
 */
class State extends Observable {
  #name;
  #keys;
  #defaults;
  #internalState;
  #autoStore;
  #stores = [];
  #pendingUpdates = {};
  #setThrottled;
  #set;

  /**
   * Adds a {@link module:ol-ishare/store~Store Store} to those that a state instance will update
   * @param {module:ol-ishare/store~Store} store Store to add.
   *     If the store is already registered then this will reregister it (and potentially change whether it is the default).
   * @param {Boolean} [makeDefault=false] Whether the store should be made the default
   */
  addStore(store, makeDefault) {
    this.removeStore(store);
    if (makeDefault) {
      this.#stores.unshift(store);
    } else {
      this.#stores.push(store);
    }
  }

  /**
   * Removes a {@link module:ol-ishare/store~Store Store} from those that a state instance will update
   * @param {module:ol-ishare/store~Store} store Store to remove.
   *     If the store is the current default, then the next one in the list of stores will become the default.
   *     To make a specific store the use {@link module:ol-ishare/state.State#setDefaultStore setDefaultStore}.
   * @return {Boolean} whether the supplied store was found in the list of stores.
   */
  removeStore(store) {
    const index = this.#stores.indexOf(store);
    const found = index > -1;
    if (found) {
      this.#stores.splice(index, 1);
    }
    return found;
  }

  /**
   * Sets a registered store to the default. If not currently registered, nothing will happen.
   *
   * @param {module:ol-ishare/store~Store} store The store to make default
   * @returns {Boolean} Whether the store was found and changed to the default
   */
  setDefaultStore(store) {
    const found = this.#stores.includes(store);
    if (found) {
      this.addStore(store, true);
    }
    return found;
  }
  /**
   * @param {Array<module:ol-ishare/store~Store>} [stores] Store instances to update when state changes.
   *     The first store in the array will be used as the default from which state will be retrieved.
   * @param {Object} [options] Core State options
   * @param {Boolean} [option.autoStore=true] Whether to automatically send the state to all registered Store instances.
   *     If not set, then {@link module:ol-ishare/state.State#sendToStores sendToStores()} must be called when an store update is required.
   * @param {Number} [options.interval=250] Minimum interval in milliseconds between state upates.
   *     (And therefore between automatically sending state to stores, if configured.)
   * @param {String} [options.name] Use for disambugating instances in Arrays, etc..
   * @param {Object} [options.defaults] Default values for any or all keys in the state
   */
  constructor(stores, options) {
    options = Object.assign(
      { autoStore: true, interval: 250, name: undefined, defaults: undefined },
      options
    );
    super();
    this.#keys = this.constructor.VALID_KEYS;
    if (!this.#keys || this.#keys.length === 0) {
      throw Error('No state definition supplied');
    }
    Object.freeze(this.#keys);

    const defaults = Object.fromEntries(this.#keys.map((k) => [k, undefined]));
    if (options.defaults) {
      for (const key of this.#keys) {
        defaults[key] = this.constructor.sanitizeValue(options.defaults[key]);
      }
    }
    this.#defaults = defaults;
    Object.freeze(this.#defaults);

    this.#internalState = Object.seal(Object.assign({}, this.#defaults));

    this.#autoStore = options.autoStore !== false; //use default if not set or set incorrectly
    if (isString(options.name)) {
      this.#name = options.name;
    }

    if (stores instanceof Store) {
      stores = [stores];
    }
    if (stores instanceof Array) {
      for (const store of stores) {
        if (store instanceof Store) {
          this.addStore(store);
        } else {
          console.warn('Non-store in ol-ishare State stores option');
        }
      }
    }

    // private method declarations not widely supported so assigning function to private field
    this.#set = function (dict) {
      if (dict) {
        dict = Object.assign({}, this.#pendingUpdates, dict);
      } else {
        dict = this.#pendingUpdates;
      }
      this.#pendingUpdates = {};
      const updatedKeys = [];
      for (const [key, value] of Object.entries(dict)) {
        if (this.keys.includes(key)) {
          const sanitized = this.constructor.sanitizeValue(value);
          if (!isEqual(this.#internalState[key], sanitized)) {
            this.#internalState[key] = sanitized;
            updatedKeys.push(key);
          }
        } else {
          console.warn('Non-state key supplied to ol-ishare State: ' + key);
        }
      }
      if (updatedKeys.length > 0) {
        this.dispatchEvent({ type: 'updated', state: this.state });
        if (this.#autoStore) {
          this.sendToStores();
        }
      }
    };
    this.#setThrottled = throttle(this.#set, options.interval);
  }

  /**
   * All keys in the state
   * @type {Array<String>}
   */
  get keys() {
    return this.#keys.slice();
  }

  /**
   * The optional name specified at instanciation or class name
   * @type {String}
   */
  get name() {
    return this.#name || this.constructor.name;
  }

  /**
   * Get the value(s) for the specified key(s)
   * @param {Array<String>} keys Key or keys to get from state
   * @returns {Object} Values for each requested key
   */
  get(keys) {
    const dict = {};
    if (!(keys instanceof Array)) {
      keys = [keys];
    }
    for (const key of keys) {
      if (typeof key !== 'string') {
        console.warn('ol-ishare State key must be a string, found ' + key + '');
      } else if (this.keys.includes(key)) {
        dict[key] = this.#internalState[key];
      }
    }

    return dict;
  }

  /**
   * Set the value(s) for the specified key(s).
   *
   * Note that:
   *     (a) all values are deep-copied and checked for casting to JSON,
   *     and (b) updates to the state are throttled by the `options.interval` value.
   * @param {String|Object} keyOrDict Key of value to set in state, or key-value pairs.
   * @param {*} [value] If `keyOrDict` is a string key, this is for the value to set for that key.
   */
  set(keyOrDict, value) {
    let dict;
    if (isString(keyOrDict)) {
      dict = { [keyOrDict]: value };
    } else {
      dict = Object.assign({}, keyOrDict);
    }
    // ensure that all changes get swept up into update on throttle interval
    this.#pendingUpdates = Object.assign({}, this.#pendingUpdates, dict);
    this.#setThrottled();
  }

  /**
   * Change the value associated with the keys to those specified in `options.defaults`. If not set, they will be `undefined`.
   *
   * @param {String} keys Keys to reset
   */
  reset(keys) {
    if (!(keys instanceof Array)) {
      keys = [keys];
    }
    const defaults = keys.reduce((obj, key) => {
      obj[key] = this.#defaults[key];
      return obj;
    }, {});
    this.set(defaults);
  }

  /**
   * Sends the current state to all registered stores.
   *
   * @returns {Promise} Resolves to the keys of values that were successfully stored.
   */
  sendToStores() {
    const state = this.state;
    const puts = this.#stores.map((store) => {
      return store.put(state);
    });
    return Promise.allSettled(puts).then((updatedKeys) => {
      return updatedKeys;
    });
  }

  /**
   * Updates state with values from the default {@link module:ol-ishare/store~Store Store} (or a specified alternative).
   *
   * @param {module:ol-ishare/store~Store} [store] Non-default store to use.
   * @returns {Promise} Promise that resolves to the current state after it has been updated from the store
   */
  updateFromStore(store) {
    if (!store) {
      store = this.#stores[0];
    }

    if (!(store instanceof Store)) {
      return Promise.reject(
        new Error('Cannot update ol-ishare State from non-Store')
      );
    }
    if (!this.#stores || !this.#stores.includes(store)) {
      console.warn('Updating ol-ishare State from non-registered Store');
    }
    const instance = this;
    return store.fetch(this.keys).then((storedState) => {
      // update immediately
      instance.#set(storedState);
      return this.state;
    });
  }

  /**
   * State at the time accessed
   *
   * @type {Object}
   */
  get state() {
    return Object.assign({}, this.#internalState);
  }

  /**
   * Default values for the state (if any)
   *
   * @type {Object}
   */
  get defaults() {
    return Object.assign({}, this.#defaults);
  }

  /**
   * The Store from which to fetch states by default
   *
   * @type {module:ol-ishare/store~Store}
   */
  get defaultStore() {
    return this.#stores[0];
  }

  /**
   * Combines state default values, the passed in options, and the current state
   *
   * The actual method of combining the options may vary from class to class, but the base class method should be
   *     a reasonable default for applications with a single options object
   * @param {Object} options Object containing settings used to initialize an application
   *     but could be anything - completely appication dependent.
   * @returns {Object} Default implementation returns new options object.
   *     Sub-classes should return the same types of objects passed as arguments, but definitely return something
   *     which makes it possible to pass the updated options to the application in a few steps (ideally one).
   */
  override(options) {
    return State.overrideObjects(this.defaults, options, this.state);
  }

  // required sub-class methods

  /**
   * Start tracking the changes made to the given application.
   *
   * This will hook into or listen to events or request the settings (or use some other method) from a single instance
   *     of an application and {@link module:ol-ishare/state.State#set set} state values accordingly.
   * The implementation will be wholly defined in the sub-class but it must use `set`
   *     to update state and must be able to be undone in {@link module:ol-ishare/state.State#stopWatching stopWatching}.
   * Watching an application must not alter how it works in any way and trying to watch a second application must result in
   *    stopping watching the first.
   * @abstract
   * @param {*} app An instance of the application to watch
   */
  watch(app) {
    throw Error(
      'ol-ishare `state.watch()` method not implemented in ' +
        this.constructor.name
    );
  }

  /**
   * Stop tracking the changes made to the currently watched application.
   *
   * After stopping watching an application, the application should be indistinguishable from one that was never watched.
   * @abstract
   */
  stopWatching() {
    throw Error(
      'ol-ishare `state.stopWatching()` method not implemented in ' +
        this.constructor.name
    );
  }

  /**
   * Apply the given state to an application already in operation.
   *
   * Like {@link module:ol-ishare/state.State#watch watch}, this will be very application depdendent but is required if
   *     updating an application after startup, or updating a second instance of an application from the state of the first,
   *     or if not all values from the state can be set during application start.
   * @abstract
   * @param {*} app An instance of the application to which to apply the current module:ol-ishare/state.State#
   * @returns {Promise} As applying state might require slow or asynchronous calls, this must return a
   *     Promise that resolves to a list of state keys that couldn't be applied to the application instance
   */
  apply(app) {
    throw Error(
      'ol-ishare `state.apply()` method not implemented in ' +
        this.constructor.name
    );
  }

  /**
   * Combine multiple objects into a new one in a specified order
   *
   * This takes the properties of each object in turn and assigns them to a new object. Similar to `Object.assign()` but
   *     with deep copying of properties (i.e. no new).
   *
   * E.g. if `object1, object2, object3` are supplied, then the values of all properties in `object2` will override
   *     those from the same properties in `object1` (or they will be added if they don't already exist). Then the same
   *     thing happens with the properties in `object3` overriding those from both `object1` and `object2`.
   * @param {...Object} objects The objects to combine - least important first
   * @returns {Object} A new combined object.
   */
  static overrideObjects(...objects) {
    const copiedObjects = [...objects].map((obj) => {
      return JSON.parse(JSON.stringify(obj));
    });

    return Object.assign({}, ...copiedObjects);
  }

  /**
   * All the keys that the state can track.
   *
   * Used internally to create the state object.
   * @type {Array<String>}
   */
  static get VALID_KEYS() {
    return VALID_KEYS;
  }

  static sanitizeValue(value) {
    return value === undefined ? undefined : JSON.parse(JSON.stringify(value));
  }
}

export default State;
