/**
 * ```
 * import { InfoPopup } from 'ol-ishare/interaction/infopopup';
 * ```
 * @module ol-ishare/interaction/InfoPopup
 */
import Popup from 'ol-popup';
import { PointQuery } from './pointquery.js';
import { infoResultsToHtml } from '../info.js';

/**
 * InfoPopup interaction
 * @class
 * @classdesc Allows the user to view info in a popup by clicking on the map.
 * @param {Object} opt_options Interaction options
 * @param {Function} opt_options.infoResultsToHtml Custom function to convert result
 *   features to HTML for display within the popup overlay
 */
var InfoPopup = (function (PointQuery) {
  function InfoPopup(opt_options) {
    opt_options = opt_options || {};
    PointQuery.call(this, {
      markPoint: false
    });
    this.infoResultsToHtml = opt_options.infoResultsToHtml || infoResultsToHtml;
    this.popupId_ = 'iShare:InfoPopup';
  }

  if (PointQuery) {
    InfoPopup.__proto__ = PointQuery;
  }
  InfoPopup.prototype = Object.create(PointQuery && PointQuery.prototype);
  InfoPopup.prototype.constructor = InfoPopup;

  return InfoPopup;
})(PointQuery);

/**
 * Clean up current map instance
 * @private
 */
InfoPopup.prototype.cleanUpMap_ = function () {
  var map_ = this.getMap();
  if (map_) {
    var popup = map_.getOverlayById(this.popupId_);
    if (popup) {
      map_.removeOverlay(popup);
    }
  }
  PointQuery.prototype.cleanUpMap_.call(this);
};

/**
 * Displays Popup containing query results and/or errors
 * @param {ol/Map~Map} map Map instance against which the query has been performed
 * @param {Array<Error|null>} errors Array containing either null (no error) or Error instance for each infoLayer (in the same order as `infoLayers`)
 * @param {Array<ol/layer/Layer~Layer>} infoLayers Info layers passed to `infoResultsToHtml`
 * @param {Array<FeatureCollection>} featureCollections OpenLayers FeatureCollections
 * @param {Array<Float>} coordinates Location queried
 */
InfoPopup.prototype.resultHandler = function (
  map,
  errors,
  infoLayers,
  featureCollections,
  coordinates
) {
  var popup = map.getOverlayById(this.popupId_);
  if (popup) {
    // Remove the tooltip class
    popup.getElement().classList.remove('ol-popup-tooltip');
    var html = this.infoResultsToHtml(errors, infoLayers, featureCollections);
    if (html.trim().length) {
      popup.show(coordinates, html);
      popup.dispatchEvent({ type: 'change:position' });
    } else {
      popup.hide();
    }
  }
};

/**
 * Requests info for all layers marked as displaying info and display popup at `coordinates`
 * @param {ol/Map~Map} map Map instance on which the popup will display
 * @param {Array<Float>} coordinates Location to query
 */
InfoPopup.prototype.requestInfo = function (map, coordinates) {
  // Get or create Popup overlay
  var popup = map.getOverlayById(this.popupId_);
  if (!popup) {
    popup = new Popup({ id: this.popupId_ });
    map.addOverlay(popup);
  }

  // Add tooltip class to popup (no close button or min-width) and
  // display loading indicator
  popup.getElement().classList.add('ol-popup-tooltip');
  popup.show(coordinates, '<div class="loading"></div>');

  PointQuery.prototype.requestInfo.call(this, map, coordinates);
};

export { InfoPopup };
