/**
 * @module ol-ishare/history
 */

import { Observable } from 'ol';
import { debounce, isEqual } from 'lodash';
/**
 * History entry
 * @typedef Entry
 * @type {Object}
 * @property {ol/coordinate~Coordinate} center
 * @property {Number} resolution
 */

/**
 * Object for remembering and recalling previous map views
 *
 * @class
 * @param { ol/Map~Map} map The OpenLayers map for which to track view history
 * @param {Object} [options] History options
 * @param {Number} [options.granularity=250] Minimum time to wait between map view changes before adding them to the history
 */
class ViewHistory extends Observable {
  #moveTime;
  constructor(map, options) {
    options = Object.assign({ moveTime: 500 }, options);
    super();
    this.#moveTime = options.moveTime;
    this.map = map;
    var internal = {
      entries: [],
      position: 0,
      updating: false
    };
    function createEntry(view) {
      var entry = {
        center: view.getCenter(),
        resolution: view.getResolution()
      };
      return entry;
    }
    function clampToEntries(value) {
      return Math.min(Math.max(value, 0), internal.entries.length - 1);
    }
    /**
     * @returns {Number} The number of entries in the history
     */
    this.getSize = function () {
      return internal.entries.length;
    };
    /**
     * @returns {Number} The current position in the history
     */
    this.getPosition = function () {
      return internal.position;
    };
    /**
     * @param {Number} position Sets the view to the one from the specified position in the history.
     *    Values below or above the bounds of the history will set the map to either the first or last entry, respectively
     */
    this.setPosition = function (position) {
      if (!Number.isInteger(position)) {
        return;
      }
      position = clampToEntries(position);
      var entry = this.getEntry(position);
      if (!entry) {
        return;
      }
      internal.updating = true;
      this.setView(entry);
      if (internal.position !== position) {
        internal.position = position;
        this.dispatchEvent({
          type: 'change:history',
          position: position,
          atStart: position === 0,
          atEnd: position === internal.entries.length - 1
        });
      }
    };
    /**
     * @param {Number} [position=this.getPosition()] Fetches the entry from the specified position in the history.
     *    Values below or above the bounds of the history will return either the first or last entry, respectively
     *    If no value is passed, returns the current entry
     * @returns {module:ol-ishare/history~Entry} View history entry object
     */
    this.getEntry = function (position) {
      if (position === undefined) {
        position = this.getPosition();
      } else if (!Number.isInteger(position)) {
        return;
      } else {
        position = clampToEntries(position);
      }
      return internal.entries[position];
    };
    var granularity = (options && options.granularity) || 250;
    var history = this;
    function onMoveEnd(evt) {
      if (internal.updating) {
        internal.updating = false;
      } else {
        var entry = createEntry(evt.map.getView());
        if (internal.entries.length > internal.position + 1) {
          internal.entries = internal.entries.slice(0, internal.position + 1);
        }
        if (!isEqual(internal.entries[internal.position], entry)) {
          internal.entries.push(entry);
          internal.position = internal.entries.length - 1;
          history.dispatchEvent({
            type: 'change:history',
            atStart: internal.position === 0,
            atEnd: true
          });
        }
      }
    }
    this.map.on('moveend', debounce(onMoveEnd, granularity));
    internal.entries.push(createEntry(this.map.getView()));
  }

  /**
   * Updates the map to the view with the specified centre and resolution, creating a new history entry in the process
   * @param {module:ol-ishare/history~Entry} entry A history entry object
   */
  setView(entry) {
    if (
      entry.hasOwnProperty('center') &&
      Array.isArray(entry.center) &&
      entry.center.length === 2 &&
      Number.isFinite(entry.center[0]) &&
      Number.isFinite(entry.center[1]) &&
      Number.isFinite(entry.resolution)
    ) {
      var view = this.map.getView();
      view.animate({
        center: entry.center,
        resolution: entry.resolution,
        duration: this.#moveTime
      });
    }
  }
  /**
   * Sets map view to that at `steps` changes ago.
   * @param {Number} [steps=1] The number of entries in the history to move back.
   *    If this is greater than the number of entires prior to the current point in the map's history,
   *    the map will be set to the first entry (normally the map's initial view).
   */
  back(steps) {
    if (steps === undefined) {
      steps = 1;
    } else if (
      !Number.isInteger(steps) ||
      (Number.isInteger(steps) && steps < 0)
    ) {
      return;
    }
    this.setPosition(this.getPosition() - steps);
  }

  /**
   * Sets map view to that at `steps` changes after the current one in the history.
   * @param {Number} [steps=1] The number of entries in the history to move forwards.
   *    If this is greater than the number of entires after the current point in the map's history,
   *    the map will be set to the last entry.
   */
  forward(steps) {
    if (steps === undefined) {
      steps = 1;
    } else if (
      !Number.isInteger(steps) ||
      (Number.isInteger(steps) && steps < 0)
    ) {
      return;
    }
    this.setPosition(this.getPosition() + steps);
  }
}
export { ViewHistory };
