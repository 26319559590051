/**
 * @module ol-ishare/http
 */

/**
 * Appends query parameters to a URI.
 *
 * @param {String} uri The original URI, which may already have query data.
 * @param {!Object} params An object where keys are URI-encoded parameter keys,
 *     and the values are arbitrary types or arrays.
 * @return {String} The new URI.
 */
function appendParams(uri, params) {
  var keyParams = [];
  // Skip any null or undefined parameter values
  Object.keys(params).forEach(function (k) {
    if (params[k] !== null && params[k] !== undefined) {
      keyParams.push(k + '=' + encodeURIComponent(params[k]));
    }
  });
  var qs = keyParams.join('&');
  // remove any trailing ? or &
  uri = uri.replace(/[?&]$/, '');
  // append ? or & depending on whether uri has existing parameters
  uri = uri.indexOf('?') === -1 ? uri + '?' : uri + '&';
  return uri + qs;
}

/**
 * Checks HTTP status of fetch response, if OK return response else throw an Error with details
 * @package
 * @param {Response} response Response instance returned by fetch
 * @returns {Response} Response instance with OK HTTP status
 * @throws {Error} Error instance with `address` (URL), `errno` (HTTP status) and `info` (HTTP status text)
 */
function checkHttpStatusIsOk(response) {
  if (response.ok) {
    return response;
  } else {
    var err = new Error('Request rejected with status ' + response.status);
    err.address = response.url;
    err.errno = response.status;
    err.info = response.statusText;
    throw err;
  }
}

export { appendParams, checkHttpStatusIsOk };
