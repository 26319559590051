import State from './State.js';
import { findLayers, getCurrentBaseLayer } from '../olutil.js';
import { unByKey } from 'ol/Observable';

const VALID_KEYS = Object.freeze([
  'profile',
  'view',
  'layers',
  'defaultLayers',
  'baseMap'
]);

function getViewSetting(map) {
  const view = map.getView();
  const centre = view.getCenter();
  return {
    easting: Math.round(centre[0]),
    northing: Math.round(centre[1]),
    resolution: view.getResolution()
  };
}

function getBaseMapName(map) {
  const baseLayer = getCurrentBaseLayer(map);
  let name;
  if (baseLayer) {
    const baseMapConf = baseLayer.get('iShare:config');
    name = baseMapConf.mapName;
  }
  return name;
}

function getLayerOptionsFromLayer(layer) {
  const conf = layer.get('iShare:config');
  return {
    name: conf.layerName,
    visible: layer.getVisible(),
    opacity: layer.getOpacity()
  };
}

function getCurrentLayerOptions(map) {
  const dataLayers = findLayers(map.getLayerGroup(), (layer) => {
    return (
      !layer.getLayers &&
      layer.get('type') !== 'base' &&
      layer.get('iShare:config')
    );
  });
  return dataLayers.map((layer) => {
    return getLayerOptionsFromLayer(layer);
  });
}
/**
 * State tracker for LiteMap instances
 *
 * @extends module:ol-ishare/state.State
 * @see {@link module:ol-ishare/state.State Base class} for detailed description
 * @param {Array<module:ol-ishare/store~Store>} stores See `stores` parameter in {@link module:ol-ishare/state.State base class documentation}
 * @param {Object} options See `options` parameter in {@link module:ol-ishare/state.State base class documentation}
 * @memberof module:ol-ishare/state
 **/
class LiteMap extends State {
  #layersListener;
  #moveListener;
  constructor(stores, options) {
    options = Object.assign({}, options);
    super(stores, options);
  }

  /**
   * Start tracking the changes made to the given {@link module:ol-ishare/litemap~LiteMap LiteMap}.
   *
   * This works by listening to `change` events from the LiteMap's OpenLayers map view and layers.
   * @param {module:ol-ishare/litemap~LiteMap} lite LiteMap instance
   */
  watch(lite) {
    const state = this;
    function startWatching() {
      state.set({
        profile: lite.profile.mapName,
        view: getViewSetting(lite.map),
        baseMap: getBaseMapName(lite.map),
        layers: getCurrentLayerOptions(lite.map)
      });
      unByKey(state.#moveListener);
      unByKey(state.#layersListener);

      function onMapMove(evt) {
        state.set('view', getViewSetting(lite.map));
      }
      function onLayersChange(evt) {
        state.set({
          baseMap: getBaseMapName(lite.map),
          layers: getCurrentLayerOptions(lite.map)
        });
      }

      state.#moveListener = lite.map.on('moveend', onMapMove);
      state.#layersListener = lite.map
        .getLayerGroup()
        .on(['change'], onLayersChange); //this picks up all changes, but once per layer, and only if layers are visible
    }
    this.stopWatching();

    if (lite.profile) {
      startWatching();
    } else {
      lite.on('profileload', startWatching);
    }
  }

  /**
   * Stops tracking changes made to the currently watched {@link module:ol-ishare/litemap~LiteMap LiteMap}.
   *
   * Removes event listeners created in {@link module:ol-ishare/state.LiteMap#watch watch()}.
   */
  stopWatching() {
    unByKey(this.#moveListener);
    unByKey(this.#layersListener);
  }

  /**
   * Apply the given state to an already created {@link module:ol-ishare/litemap~LiteMap LiteMap}
   *
   * NOT CURRENTLY IMPLEMENTED, this is a bug
   *
   * @param {module:ol-ishare/litemap~LiteMap} lite An instance of the application to which to apply the current state.
   * @returns {Promise} Returns Promise that resolves to a list of keys that had values which couldn't be applied to the LiteMap.
   */
  apply(lite) {
    // if profile same, use current profile, else profile.loadProfile,
    // alter profile object layers to match state layers
    // alter profile default basemap to match state basemap
    // replicate LiteMap code to turn state view into something map.setView() understands?
    // replicate LiteMap code to interpret state defaultLayers
    // lite.loadProfile with altered profile object
    return Promise.reject(Error('Not implemented yet'));
  }

  static get VALID_KEYS() {
    return VALID_KEYS;
  }
}

export default LiteMap;
