import { Control } from 'ol/control';
import { ViewHistory } from '../history.js';

class HistoryNavigation extends Control {
  constructor(options) {
    var element = document.createElement('div');
    element.className = 'oli-history ol-unselectable ol-control';
    options = Object.assign({ element: element }, options);

    var back = document.createElement('button');
    back.innerHTML = options.backHtml || '&lArr;';
    back.title = options.backTitle || 'Navigate back';
    back.setAttribute('aria-label', back.title);
    back.className = 'oli-history-back';

    var forward = document.createElement('button');
    forward.innerHTML = options.forwardHtml || '&rArr;';
    forward.title = options.forwardTitle || 'Navigate forward';
    forward.setAttribute('aria-label', forward.title);
    forward.className = 'oli-history-forward';

    element.appendChild(back);
    element.appendChild(forward);

    super({ element: element, target: options.target });
    this.disableButton_(back);
    this.disableButton_(forward);
    this.back_ = back;
    this.forward_ = forward;

    back.addEventListener('click', this.navigateBack.bind(this), false);
    forward.addEventListener('click', this.navigateForward.bind(this), false);
  }

  disableButton_(button) {
    button.disabled = true;
    button.classList.add('oli-disabled');
  }
  enableButton_(button) {
    button.disabled = false;
    button.classList.remove('oli-disabled');
  }

  navigateBack(evt) {
    if (this.getMap()) {
      this.history.back();
    }
  }
  navigateForward(evt) {
    if (this.getMap()) {
      this.history.forward();
    }
  }

  setMap(map) {
    super.setMap(map);
    var nav = this;
    this.history = new ViewHistory(this.getMap());
    this.history.on('change:history', function (evt) {
      if (evt.atEnd) {
        nav.disableButton_(nav.forward_);
      } else {
        nav.enableButton_(nav.forward_);
      }
      if (evt.atStart) {
        nav.disableButton_(nav.back_);
      } else {
        nav.enableButton_(nav.back_);
      }
    });
  }
}

export { HistoryNavigation };
