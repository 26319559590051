import packageJson from '../../package.json';
/*regular expression from https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string */
const re =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
const parts = re.exec(packageJson.version);

function major() {
  return 'v' + parts[1];
}
function minor() {
  return 'v' + parts.slice(1, 3).join('.');
}
function patch() {
  return 'v' + parts.slice(1, 4).join('.');
}
function majorNumber() {
  return Number(parts[1]);
}
function minorNumber() {
  return Number(parts[2]);
}
function patchNumber() {
  return Number(parts[3]);
}
function prerelease() {
  return parts[4];
}
function full() {
  let ver = patch();
  if (ver[4]) {
    ver = ver + '-' + parts[4];
  }
  return ver;
}

const version = {
  full: full,
  major: major,
  majorNumber: majorNumber,
  minor: minor,
  minorNumber: minorNumber,
  patch: patch,
  patchNumber: patchNumber,
  prerelease: prerelease,
  toString: function () {
    return full();
  }
};

export default version;
