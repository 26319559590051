import Store from './Store.js';
import { isEqual } from 'lodash';

function getStorage(useSession) {
  let storage = null;
  let type;
  const test = '-----ol-ishare---test-----';
  if (useSession !== true) {
    try {
      localStorage.setItem(test, test);
      localStorage.getItem(test);
      localStorage.removeItem(test);
      storage = localStorage;
      type = 'local';
    } catch (e) {
      console.warn('LocalStorage not available');
      console.info(e);
    }
  }
  if (!storage) {
    try {
      sessionStorage.setItem(test, test);
      sessionStorage.getItem(test);
      sessionStorage.removeItem(test);
      storage = sessionStorage;
      type = 'session';
    } catch (e) {
      console.warn('SessionStorage not available');
      console.info(e);
    }
  }

  return [type, storage];
}

/**
 * Store items with the Web Storage API
 *
 * By default will attempt to use Local Storage and fall back to Session Storage. If neither can be used, throws an error.
 *
 * Storage keys will gain a prefix that includes the URL path to diffentiate the saved settings in one application from those
 * in another but under the same domain. *Note*: this means that pages stored by a default page loaded from the URL without
 *     the page name will have different keys to those when the page is loaded with its full path.
 *
 * @extends module:ol-ishare/store.Store
 *
 * @param {Object} [options] Settings for working with Web Storage
 * @param {String} [options.prefix='oli-store/[path]'] Sets the prefix for all keys - change to
 *     allow applications in the same domain to use the same items.
 * @param {Boolean} [options.useSession=false] Whether to only try to use Session Storage
 * @memberof module:ol-ishare/store
 */
class WebStorage extends Store {
  #prefix;
  #storage;
  #storageType;
  constructor(options) {
    const path = window.location.pathname;
    options = Object.assign(
      {
        prefix: 'oli.store' + path,
        useSession: false
      },
      options
    );
    super(options);
    [this.#storageType, this.#storage] = getStorage(options.useSession);
    if (!this.#storage) {
      console.error(Error('No Web Storage API available'));
    }
    this.#prefix = options.prefix + '#';
  }

  /**
   * WebStorage type in use
   *
   * @type {('local'|'session'|undefined)}
   */
  get type() {
    return this.#storageType;
  }

  // required sub-class methods
  put(dict) {
    if (!this.#storage) {
      console.warn('Attempt to put to WebStorage but no storage API available');
      return Promise.reject({});
    }
    return super.put(dict).then((validatedKeys) => {
      const updatedKeys = [];
      for (const key of validatedKeys) {
        const storageKey = this.generateStorageKey(key);
        const newValue = dict[key];

        if (this.#storage.getItem(storageKey) !== null) {
          const currentValue = JSON.parse(this.#storage.getItem(storageKey));
          if (isEqual(currentValue, newValue)) continue;
        }
        if (newValue === undefined) {
          // cannot store `undefined`, but having no value is the same thing

          if (this.#storage.hasOwnProperty(storageKey)) {
            this.#storage.removeItem(storageKey);
          }
        } else {
          this.#storage.setItem(storageKey, JSON.stringify(newValue));
        }
        updatedKeys.push(key);
      }
      return updatedKeys;
    });
  }

  fetch(keys) {
    if (!this.#storage) {
      console.warn(
        'Attempt to fetch from WebStorage but no storage API available'
      );
      return Promise.reject({});
    }
    return super.fetch(keys).then((dict) => {
      for (const key in dict) {
        const storageKey = this.generateStorageKey(key);
        if (this.#storage.hasOwnProperty(storageKey)) {
          const value = this.#storage.getItem(storageKey);
          dict[key] = JSON.parse(value);
        }
      }
      return dict;
    });
  }

  discard(keys) {
    if (!this.#storage) {
      console.warn(
        'Attempt to discard items in WebStorage but no storage API available'
      );
      return Promise.reject([]);
    }
    return super.discard(keys).then((validatedKeys) => {
      const deletedKeys = [];
      for (const key of validatedKeys) {
        const storageKey = this.generateStorageKey(key);
        if (this.#storage.hasOwnProperty(storageKey)) {
          this.#storage.removeItem(storageKey);
          deletedKeys.push(key);
        }
      }
      return deletedKeys;
    });
  }

  generateStorageKey(key) {
    return this.#prefix + key;
  }
}

export default WebStorage;
