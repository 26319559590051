/**
 * Provides functions to move a map based on the device's reported location.
 *
 * Requires: valid Proj4JS projection to be in use by OpenLayers.
 * Requires: secure context (i.e. must be requested using HTTPS).
 *   This is needed by the standard Geolocation API.
 *   (See https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API.)
 *
 * @module ol-ishare/devicelocation
 */

import Geolocation from 'ol/Geolocation';

/**
 * Returns an error with a reason why devicelocation can't do anything
 *
 * @param {string} reason Why devicelocation isn't available
 * @returns {Error} Error with verbose message
 */
function NotAvailableError(reason) {
  return Error('ol-ishare devicelocation not available: ' + reason);
}

/**
 * Creates a temporary Geolocation object and centres the map.
 *
 * @param {ol/Map~Map} map OpenLayers Map
 * @promise {Promise}
 * @reject {Error}
 * @fulfill {ol/coordinate~Coordinate}
 * @returns {Promise.<ol/coordinate~Coordinate>} Centre of map
 */
function centerMapOnDevice(map) {
  return new Promise(function (resolve, reject) {
    if (!window.proj4) {
      reject(NotAvailableError('no Proj4'));
    } else if (!window.isSecureContext) {
      reject(NotAvailableError('context is insecure'));
    } else {
      const view = map.getView();
      const projection = view.getProjection();
      const loc = new Geolocation({
        projection: projection
      });
      loc.once('change:position', function (evt) {
        const coords = evt.target.getPosition();
        view.setCenter(coords);
        evt.target.setTracking(false);
        resolve(view.getCenter());
      });
      loc.setTracking(true);
    }
  });
}

export { centerMapOnDevice };
